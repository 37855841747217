/*
 * T_wc_lastgames
 */

$className: T_wc_lastgames;

.T_wc_lastgames.animation {

  .group-row, .qatar-logo, .team-logo-container {
    opacity: 0;
  }

}
