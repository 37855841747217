/*
 * T_wc_goalshots
 */

$className: T_wc_goalshots;

.T_wc_goalshots.animation {

  .qatar-logo, .main-headline, .pitch-container, .lineup {
    opacity: 0;
  }
}
