/*
 * T_teamfacts
 */
.T_teamfacts.animate {

  .team-name, .team-logo, .factsListBg, .factsList, .player1, .player2, .player3, .picture, .subheadline, .headline {
    opacity: 0;
  }

}
