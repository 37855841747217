/*
 * T_adlerstatistics
 */

$className: T_adlerstatistics;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;

  .custom-background-overflow {
    height: 530px;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    transform-origin: center center;
  }

  .comp-backgrounds {
    z-index: 3;
  }

  .custom-background-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    mix-blend-mode: soft-light;


    .custom-background {
      width: 100%;
      height: 100%;
      object-fit: contain;
      -webkit-mask: linear-gradient(360deg, rgba(0, 0, 0, 0) 0, black 15%);
    }
  }

  .box-bg {
    display: flex;
    position: absolute;
    z-index: 4;

    /* 2*sin(120/2)*h = w
       h = w / (sin(60)*2)
       h = w * 0.57736
    */
    .triangle1:before {
      content: "";
      display: block;
      padding-top: 66%;
    }

    .triangle1 {
      background: #014389;
      clip-path: polygon(0 50%, 50% 100%, 100% 50%, 50% 0);
      width: 120px;
      display: inline-block;
      margin: 5px;
    }
  }

  &.side-away {
    .triangle1 {
      background: #e60026;
    }
  }


  .periods-container {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 364px;
    flex-direction: column;

    & > div {
      line-height: 1;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      margin-bottom: 0;
      position: relative;
    }

    .box-score {
      position: relative;
      z-index: 5;
    }
  }

  .number-of-viewers-container {
    position: absolute;
    z-index: 100;

    //padding: 10px 10px;
    line-height: 1;
    text-align: center;
    left: 0;
    right: 0;
    top: 101px;
    //background-color: white;
    //color: #003f8a;
    font-family: 'Predator-Sans-SemiLight';
    font-weight: 100;
    font-size: 28px;
    //opacity: 0.8;


    .number-of-viewers {

    }
  }

  .extra-time-info {
    display: block;
    font-size: 22px;
    text-align: center;
    margin-top: 4px;
    margin-left: 8px;
  }

  .score-and-period-block {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50px;
    z-index: 100;
  }

  .score-container {
    font-size: 360px;
    z-index: 10;
    position: relative;
    top: 20px;
    left: 0;
    width: 100%;


    & > div {
      margin: auto;
      display: flex;
      justify-content: center;
      width: 900px;
    }

    .score-separator {
      width: 120px;
      text-align: center;
      position: relative;
    }

    .score-away {
      position: relative;
      text-align: left;
    }

    .score-home {
      text-align: right;
    }

    .score-home, .score-away {
      width: 395px;
      //-webkit-text-stroke: 1px black;
    }

  }

  .home-scorers, .away-scorers {
    font-family: "Predator-Sans-SemiLight";
    display: flex;
    min-height: 335px;
    flex-direction: column;
    justify-content: center;
    bottom: 145px;
    max-width: 295px;
    white-space: nowrap;

    & > div {

    }
  }

  .home-scorers {
    position: absolute;
    right: auto;
    left: 75px;
    font-size: 25px;
  }

  .away-scorers {
    position: absolute;
    left: auto;
    right: 75px;

    font-size: 25px;
    text-align: right;
  }

  .teams-block {
    position: absolute;
    top: 608px;
    width: 100%;
    z-index: 100;

    .home-name, .away-name {
      position: absolute;
      top: 15px;
      font-size: 98px;
      color: black;
      opacity: 0.6;
    }

    .home-name {
      left: 90px;
    }

    .away-name {
      right: 90px;
    }

    .team-logo-container {
      position: absolute;
      width: 180px;
      height: 180px;
      top: 104px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
      }

      &.home {
        top: 5px;
        left: 380px;
      }

      &.away {
        top: 5px;
        right: 380px;
      }
    }


  }

  .sponsors {
    position: absolute;

    &.sponsor-sap {
      right: 50px;
      width: 160px;
      bottom: 45px;
    }

    &.sponsor-del {
      left: 50px;
      width: 150px;
      top: 45px;
    }
  }

  .periods-container {
    left: 0;
    top: 128px;
    right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }

  .statistics-block {
    color: #fff;
    top: 810px;
    left: 0;
    right: 0;
    font-size: 28px;
    position: absolute;
    z-index: 10;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;

    .stats-mark-points {
      display: flex;
      position: absolute;
      z-index: 10;
      bottom: -20px;

      & > div {
        //border-left: 2px solid #7b0003;
        height: 10px;
        width: 32.4px;

        &:last-child {
          //border-right: 2px solid #7b0003;
        }
      }
    }

    .home-value, .away-value {
      position: absolute;
      font-size: 30px;
      z-index: 15;
      bottom: -20px;
      z-index: 5;


      > div {
        position: relative;
        z-index: 10;
      }

      .box-bg {
        position: absolute;
        left: -28px;
        top: -7px;
        z-index: 4;
      }
    }

    .home-value {
      right: 330px;
    }

    .away-value {
      left: 330px;
    }

    .stat-entry-block {
      top: 0;
      left: 0;
      position: relative;
      margin: auto;
      height: 82px;
    }

    .label-container {
      height: 100%;
      width: 220px;
      position: relative;
      z-index: 50;
      line-height: 43px;
      top: 2px;

      .label {
        text-align: center;
      }
    }

    .stat-entry {
      position: relative;
    }

    .stat-line-home-container, .stat-line-away-container {
      position: absolute;
      //left: 151px;
      top: 25px;
      height: 4px;
      width: 500px;
      //overflow: hidden;
      transform-style: preserve-3d;
      //border: 3px solid black;
      box-sizing: border-box;
      //border-radius: 20px;
      //background-color: #000000;

      .filler:after {
        content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: auto;
        right: 0;
        z-index: 15;
        transform-style: preserve-3d;
      }

    }

    .filler {
      width: 0;
      height: 100%;
      position: absolute;
      //top: -3%;
      left: 0;
      z-index: 10;
      overflow: hidden;
      transform-style: preserve-3d;
      //background-color: #e40019;
      //border-radius: 20px;
      display: flex;
      flex-direction: column;
      height: 17px;
      margin-top: -8.5px;
      justify-content: space-between;


      > div {
        height: 6px;
        width: 100%;
        background-color: white;
      }
    }

    .stat-line-home-container {
      right: 415px;

      .filler {
        right: 0;
        left: auto;
        //background-color: #024289;
        //box-shadow: 0 0 8px 2px #66abf2;
      }
    }

    .stat-line-away-container {
      right: auto;
      left: 415px;

      .filler {
        left: 0;
        right: auto;
        //background: #e40520;
        //box-shadow: 0 0 8px 2px #ec5567;
      }

    }
  }

  .period-4-score {
    .box-score {
      margin-left: -10px;
    }

    .extra-time-info {
      margin-left: 16px;
    }
  }

  .actual-period-0 {
    .period-2-score {
      display: none;
    }

    .period-3-score {
      display: none;
    }

    .period-4-score {
      display: none;
    }
  }

  .actual-period-1, .actual-period-10 {
    .period-2-score {
      display: none;
    }

    .period-3-score {
      display: none;
    }

    .period-4-score {
      display: none;
    }
  }

  .actual-period-2, .actual-period-20 {
    .period-3-score {
      display: none;
    }

    .period-4-score {
      display: none;
    }
  }

  .actual-period-3, .actual-period-30 {
    .period-4-score {
      display: none;
    }
  }

  .side-away {
    .stat-line-home-container {
      left: -500px;

      .filler {
        background-color: #e40019;
      }
    }

    .stat-line-away-container {

      .filler {
        background-color: #024289;
      }

    }
  }

  .sponsors {
    z-index: 12;
  }
}

.f-1200x1500 {
  .#{$className} {
    @import "./style-1200x1500";
  }
}

.f-1920x1080 {
  .#{$className} {
    @import "./style-1920x1080";
  }
}
