/*
 * T_adlerlineup
 */

$className: T_adlerlineup;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;


  img:not([src]) {
    visibility: hidden;
  }


  .players-block-container {
    position: absolute;
    z-index: 15;
    top: 136px;
    width: 100%;
    left: 0px;
    padding: 0px 100px;
  }

  .names {
    position: absolute;
    left: 0;
    bottom: -58px;
    display: flex;
    z-index: 10;
    height: 56px;
    overflow: hidden;

    .player-data {
      > div {
        font-size: 36px;

      }
    }
  }

  .player-group-lines {
    position: absolute;
    width: 100%;

    .player-group-line-2 {
      display: none;
    }
  }

  .player-group-line, .player-group-line-2 {
    position: absolute;
    height: 56px;
    background-color: #054888;
    font-size: 63px;
    line-height: 56px;
    z-index: 10;
    top: 138px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div:nth-child(2) {
      position: absolute;
      left: 1016px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.side-away {
    .player-group-line, .player-group-line-2 {
      background-color: #E40521;
    }
  }


  .lineGoalie {
    .player-group-line {
      width: 700px;
    }
  }

  .line4 {
    .player-group-line {
      width: 1400px;
    }
  }


  //.player-group-line {
  //
  //}

  //.player-container, .image-container {
  //  width: 33.33%;
  //}


  .player-data {
    transform-style: preserve-3d;

    &.pos-forward.last-of-pos {
      margin-right: 20px;
    }

    //&.pos-defender:first {
    //  margin-left: 10px;
    //}
  }

  background-color: #0051B0;

  .teams-block {
    transform-origin: center center;
  }

  .team-vs {
    position: relative;
    left: 1560px;
    top: 920px;
    font-size: 48px;
    transform-origin: center center;
  }

  .team-logo-container {
    position: absolute;
    width: 240px;
    height: 240px;
    top: 820px;
    left: 1620px;
    bottom: auto;
    //transform: rotateZ(
    //    -22deg);

    //&.away {
    //  top: 827px;
    //  left: 1695px;
    //  height: 130px;
    //  width: 130px;
    //}

    img {
      //transform: rotateZ(
      //    22deg);
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: relative;
    }
  }


  .headline {
    font-family: "Predator-Sans-Cut";
    font-size: 120px;
    position: absolute;
    transform-origin: center center;

    text-align: center;
    width: 100%;
    font-style: italic;

    div {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 100%;
      z-index: 2;

      &.main-line {
        letter-spacing: 0.015em;
        left: 2px;
        position: relative;
      }

      &.ghost-line {
        color: #cc0a1a;
        z-index: 1;
        letter-spacing: 0
      }
    }
  }

  &.side-away {
    .headline {
      div {
        &.ghost-line {
          color: #054888;
        }
      }
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      right: auto;
      width: 155px;
      top: 30px;
      left: 50px;
      text-align: center;
      margin: auto;
    }

    &.sponsor-sap {
      left: auto;
      width: 260px;
      bottom: 45px;
      top: auto;
      right: 20px;
    }
  }

  .player-image-mask {
    //height: 180px;
    //overflow: hidden;
    //-webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 45%, transparent 100%);
    position: relative;
    z-index: 5;
  }

  .player-image {
    object-fit: contain;
    -o-object-position: top;
    object-position: top;
    width: 341px;
    height: 330px;
    position: relative;
    left: 0;
    top: 26px;

    //Niggel Dawes image fix
    //&.pid-1767 {
    //  top: -55px;
    //}
    //
    ////Jordan Szwarz image fix
    //&.pid-1772 {
    //  top: -50px;
    //}
  }

  .players-block-container {
    position: absolute;
    z-index: 15;
    //transform:  rotateZ(-22deg);
    //transform-origin: top left;
    width: 100%;

    .line-row {
      color: rgba(0, 0, 0, 0.4);
      font-size: 140px;
      line-height: 1;
      position: absolute;
      left: -105px;
    }

    .players {
      display: flex;
      font-size: 18px;
      position: relative;
      z-index: 15;
      //transform: skewX(-22deg);
      margin-bottom: 10px;
      top: 7px;
      height: 136px;
      left: 0;

      .player-item-bg {
        position: relative;
        height: 140px;
        overflow: hidden;
        transform-origin: bottom center;
      }

      & > .player-data {
        width: auto;
      }
    }


    .names {
      width: 100%;

      & > .player-data {
        width: 342px;
      }
    }


    .line-label {
      font-size: 131px;
      color: #001935;
      line-height: 1;
      position: absolute;
      left: 0;
      top: 62px;
      z-index: 13;
    }

    .line1 {

      .player-separator {
        background-color: white;
      }

      .player-data {
        //border-left: 2px solid white;

        .player-lastname, .player-firstname {
          color: #ffffff;
        }

        .player-bottom-gradient {
          background: linear-gradient(0deg, rgb(229, 0, 19) 2%, rgba(138, 128, 132, 0) 80%);
        }
      }

      &.line-label {
        left: 12px;
      }

      //&.players {
      //  left: 200px;
      //}
    }

    .line2 {
      .player-separator {
        background-color: black;
      }

      .player-data {
        //border-left: 2px solid black;

        .player-lastname, .player-firstname {
          color: #174188;
          color: white;
        }

        .player-bottom-gradient {
          background: linear-gradient(0deg, #174188 2%, rgba(138, 128, 132, 0) 80%);
        }
      }

      &.line-label {
        top: 240px;
        left: -25px;
      }

      &.players {
        top: 34px;
      }
    }

    .line3 {
      .player-separator {
        background-color: white;
      }

      .player-data {
        //border-left: 2px solid white;

        .player-lastname, .player-firstname {
          color: #ffffff;
        }

        .player-bottom-gradient {
          background: linear-gradient(0deg, rgb(229, 0, 19) 2%, rgba(138, 128, 132, 0) 80%);
        }
      }

      &.line-label {
        top: 417px;
        left: -57px;
      }

      &.players {
        top: 62px;
      }
    }

    .line4 {
      .player-separator {
        background-color: black;
      }

      .player-data {
        //border-left: 2px solid black;

        .player-lastname, .player-firstname {
          color: white;
        }

        .player-bottom-gradient {
          background: linear-gradient(0deg, #174188 2%, rgba(138, 128, 132, 0) 80%);
        }
      }

      &.line-label {
        top: 595px;
        left: -88px;
      }


      &.players {
        top: 89px;
      }
    }


    .lineGoalie {
      .player-separator {
        background-color: white;
      }

      .player-data {
        //border-left: 2px solid white;

        .player-lastname, .player-firstname {
          color: #ffffff;
        }

        .player-bottom-gradient {
          background: linear-gradient(0deg, rgb(229, 0, 19) 2%, rgba(138, 128, 132, 0) 80%);
        }
      }

      //.player-data:first-child {
      //  margin-left: 490px;
      //}
      //
      //&.goalies-x3 .player-data:first-child {
      //  margin-left: 410px;
      //}

      //.player-image {
      //  left: -138px;
      //  top: -37px;
      //}

      //.player-data {
      //  width: 240px;
      //}


      &.players {
        top: 120px;
      }
    }

    .players-data-container {
      position: relative;
      left: -10px;
    }

    .player-lastname, .player-firstname {
      font-size: 24px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
      white-space: nowrap;
      z-index: 20;
      text-align: center;
    }

    .player-data {
      width: 206px;
      text-align: center;
      text-transform: uppercase;
      line-height: 1;
      position: relative;
    }

    .player-number {
      font-family: 'Predator-Sans-Cut';
      position: absolute;
      top: 34px;
      right: 0;
      bottom: 182px;
      font-size: 120px;
      text-align: center;
      z-index: 50;
      width: 100%;
      z-index: 4;
      height: 100%;
      transform-origin: bottom center;
    }

    .player-bottom-gradient {
      display: none;
      position: absolute;
      z-index: 12;
      left: -1px;
      bottom: -1px;
      height: 50px;
      width: 100%;

    }

    .player-separator {
      display: none;
      background-color: #E40521;
      width: 2px;
      height: 141px;
      position: absolute;
      left: 0;
      z-index: 20;
      top: 1px;
    }
  }

  &.other-stats {
    .jersey {
      color: white;
    }

    .sponsor-del {
      opacity: 0;
    }

    .player-lastname, .player-firstname {
      text-align: center;
    }

    img.player-image {
      visibility: hidden;
    }
  }

  &.side-away {
    .team-vs {
      color: #e40520;
    }
  }
}


.f-1200x1500 {
  .#{$className} {
    @import "./style-1200x1500";
  }
}

.f-1920x1080 {
  .#{$className} {
    @import "./style-1920x1080";
  }
}
