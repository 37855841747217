//1st = primary
//2nd = secondary
//3rd = tertiary
//4th = quaternary
//5th = quinary
//6th = senary
//7th = septenary
//8th = octonary
//9th = nonary
//10th = denary

$_1stColor: #004289;
$_2ndColor: #e5ecf3;
$_3rdColor: #0057b2;
$redColor: #c51d1d;
$greyColor: #cccccc;
