/*
 * T_wc_facts
 */
$className: T_wc_facts;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;

  .comp-match-score-widget {
    width: 100%;
  }

  .type-home {

    .comp-match-score-widget {

      .widget-container {
        .logo-container {
          width: 180px;
          height: 160px;
        }
      }

      .score, .logo-container.away, .team-vs {
        display: none;
      }
    }

  }

  .type-away {

    .comp-match-score-widget {

      .widget-container {
        .logo-container {
          width: 180px;
          height: 160px;
        }
      }

      .score, .logo-container.home, .team-vs {
        display: none;
      }
    }

  }

  .green-glow {
    color: #d2f95a;
  }

  .facts-block {
    display: flex;
    margin: 0 60px;
    line-height: 1.10;
    font-size: 42px;
    flex-wrap: wrap;
    justify-content: space-between;
    height: fit-content;

    .facts {
      width: 500px;
      //border-left: 6px solid #d2f95a;
      padding-left: 25px;

      & > p > strong:first-child {
        color: #d2f95a;
      }

      &:before {
        content: "";
        width: 70px;
        height: 5px;
        background-color: #d2f95a;
        display: block;
        margin-bottom: 25px;
      }

      p {
        margin-bottom: 5px;
      }
    }
  }

  .main-title {
    font-size: 74px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    position: absolute;
    left: 35px;
    text-align: center;
    top: 40px;
  }

  .sub-title {
    color: white !important;
    font-size: 50px;
    position: relative;
    /* line-height: 0.9; */
    text-transform: uppercase;
    font-weight: 400;
    top: 110px;
    text-align: left;
    left: 35px;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .qatar-logo {
    position: absolute;
    top: 60px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .info-block {
    position: absolute;
    top: 240px;
    left: 0;
    font-size: 30px;
    font-weight: 400;
    width: 100%;
  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
    text-align: center;
  }

  .teams-block {
    position: relative;
    //width: 500px;
    display: flex;
    justify-content: center;
    padding-bottom: 85px;

    .team-vs {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0px;
      top: 45px;
      font-size: 48px;
      font-weight: 600;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .versus {
        background-color: #bfcfd4;
        border: 8px solid #72949e;
        border-radius: 100%;
        //display: inline-block;
        //padding: 10px;
        color: #005175;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        letter-spacing: -2px;
      }
    }

    .home {
      margin-right: 260px;
    }

    .home, .away {
      //position: absolute;
      width: 220px;
      height: 200px;
      //height: 200px;
    }

    .team-name {
      font-size: 32px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 10px;
      transform-style: preserve-3d;
      width: 500px;
      margin-left: -125px;
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;
    flex-direction: column;
    /* justify-content: center; */
    /* justify-items: center; */
    align-items: center;
    display: flex;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      font-size: 60px;
      margin-bottom: 10px;
    }

    .venue-container {
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 400;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .type-global {

  }


}
