/*
 * T_wc_teamcompare_season
 */

$className: T_wc_teamcompare_season;

.T_wc_teamcompare_season.animation {

  .stat-row, .team-logo-container, .versus-info {
    opacity: 0;
  }

}
