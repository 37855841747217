.teams-block {
  .team-vs {
    display: none;
  }

  .team-logo-container {
    width: 180px;
    height: 180px;
    top: 35px;
    left: 1600px;
  }
}


//&.side-home {

//}
//
//&.side-away {

//}

.starting-six-container {
  top: 440px;
}

.players-container-splitted {

  .player-image-container {
    //height: 340px !important;

  }

  .jersey {
    text-align: center;
    right: 0;
    line-height: 1;
    white-space: nowrap;
    /* font-weight: normal; */
    z-index: 5;
    /* bottom: 20px; */
    position: relative;
    top: auto;
    /* z-index: -13333; */
    bottom: 112px;
  }

  .player-info {
    position: absolute;
    text-align: center;
    top: 11px;
    font-size: 40px;
  }

  .firstname, .lastname {
    text-shadow: 1px 1px #464646;
    position: relative;
    z-index: 5;
  }

  .lastname {
    color: #fff;
  }

  .firstname {
    //color: #64020d;
    margin-right: 8px;
  }

  .player-image-container {
    bottom: 62px;
  }


  &.line-de {
    margin-top: 200px;
  }


  &.line-fo {

    .jersey {
      //opacity: 0.8;
    }
  }

  &.line-gk {
    margin-top: 200px;
    //top: 174px;
    //left: 1515px;

    .firstname, .lastname {
      //color: #003a8e;
      //text-shadow: 1px 1px #dbdbdb;
    }

    //.player-bottom-gradient {
    //  background: linear-gradient(0deg, #0f1d36 2%, rgba(138, 128, 132, 0) 80%);
    //}
    //.player-container:nth-child(1) {
    //  .player-info {
    //    top: 120px;
    //    left: -110px;
    //  }
    //}
  }

  .player-image-container {
    //height: 320px;
  }
}

&.other-stats {
  .players-container-splitted {

    //.player-bottom-gradient {
    //  display: none;
    //}
    //
    //.jersey {
    //  font-size: 140px;
    //  top: -66px;
    //}
    //
    //.player-image-container {
    //  display: none;
    //}

  }
}

&.side-away {


}

.sponsors {
  &.sponsor-sap {
    display: none;
  }

  &.sponsor-del {
    display: none;
  }
}

&.side-away {
  .team-logo-container.home {
    display: none;
  }
}

&.side-home {
  .team-logo-container.away {
    display: none;
  }
}
