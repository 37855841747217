/*
 * T_wc_tournamentranking
 */
$className: T_wc_tournamentranking;

.T_wc_tournamentranking.animation {

  .table-head, .table-row, .teilnahmen-note, .table-head {
    opacity: 0;
  }
}
