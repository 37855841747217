/*
 * T_wc_matchdayoverview
 */
$className: T_wc_matchdayoverview_2;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;

  .comp-template-block .wc-content-v2 {
    width: 1800px;
  }

  .mid-indicator {
    font-size: 14px;
    display: none;
    position: absolute;
  }

  .row-day {
    display: flex;
  }

  .team-name {
    text-transform: uppercase;
  }


  .stage-Final {
    .comp-live-status-badge {
      &.live-status {
        top: 8px;
        right: 0;
      }
    }
  }

  .comp-live-status-badge {
    &.live-status {
      text-transform: uppercase;
      line-height: 1;
      display: flex;
      /* align-items: center; */
      /* top: -20px; */
      justify-content: flex-end;
      font-weight: 500;
      position: relative;
      font-size: 18px;
      top: 0;
      right: 0;
    }

    .animation-container {
      height: 20px;
      width: 70px;
    }


  }

  %match-right {
    align-items: flex-end;


    .match-block {
      .row-score {
        align-items: flex-start;
        right: auto;
        left: 30px;
      }


      .team-home {
        margin-left: auto;
      }

      .team-away {
        margin-left: auto;
      }

      .row-score-status {
        left: 20px;
        right: auto;
      }

      .row-score-weekday {
        left: 14px;
        right: auto;
      }
    }
  }

  %match-left {
    align-items: flex-start;

    .match-block {

      .row-score {
        align-items: flex-end;
        right: 30px;
        left: auto;
      }

      .team-name {
        text-align: left;
      }

      .team-home {
        margin-left: 0;
      }

      .team-away {
        margin-left: 0;
      }

      .row-score-status {
        right: 20px;
        left: auto;
      }
    }
  }


  .main-header {
    font-weight: 600;
    top: 55px;
    padding-right: 0;
    right: 0;
    left: 0;
    font-size: 60px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    z-index: 5;
    text-align: center;
  }

  .sub-header {
    top: 55px;
    padding-right: 0;
    left: 50px;
    font-size: 35px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 500;
    z-index: 5;
    text-align: center;
    align-content: center;
    line-height: 1;
    height: 54px;
  }

  .main-label-final {
    top: 320px;
    padding-right: 0;
    right: 0;
    font-size: 70px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
    text-align: center;
    width: 100%;
    color: white;
    filter: drop-shadow(2px 4px 6px black);


    div {
      margin-right: 90px;
    }
  }

  .main-label-3rd {
    top: 700px;
    padding-right: 0;
    right: 0;
    font-size: 48px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
    text-align: center;
    width: 100%;
    color: white;
    filter: drop-shadow(2px 4px 6px black);
  }

  .world-cup-trophy {
    display: block;
    position: absolute;
    left: 0;
    bottom: -80px;
    right: 0;
    margin: 0 auto;
    width: 120px;
    z-index: 1;
    filter: drop-shadow(2px 4px 6px black);
  }

  .match-time {
    left: 0;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    row-gap: 1px;
    width: 100%;
    flex-direction: column;
    font-weight: 400;

    div {
      font-size: 14px !important;
      line-height: 1;
    }
  }

  .match-block {
    position: relative;
    width: 210px;
    padding: 8px 10px;
    border: 3px solid rgb(59, 126, 137);
  }

  .team-info-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    margin-bottom: 6px;
    align-items: center;
  }

  .team-info-container {
    display: flex;
    align-items: center;
  }

  .team-info-divider {
    width: '100%';
    height: 2px;
    background-color: rgb(59, 126, 137);
  }

  .final-team-wrap {
    display: flex;
    justify-content: space-between;
  }

  .final-team-info-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .team-home, .team-away {
      height: 50px;
      width: 60px;
    }
  }

  .final-team-info-container {
    // align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .team-name {
      margin-top: 12px;
      text-align: center;
    }
  }

  .final-caret {
    font-size: 25px;
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 52px;
  }

  .wc-content-header {
    height: 200px;
  }

  .wc-content-table {
    position: relative;
    height: 695px;
    top: 5px;
  }

  .left-lines {
    position: absolute;
    top: 130px;
  }

  .final-line {
    height: 2px;
    //background-color: white;
    width: 20px;
    position: absolute;
    top: 385px;
    left: 0;
    border-bottom: 3px solid rgb(59, 126, 137);

    &.final-line-1 {
      left: 725px;
    }

    &.final-line-2 {
      left: 1055px;
    }
  }

  .right-lines {
    position: absolute;
    top: 130px;
    right: 0px;

    .line {

      &.line1 {
        border-right: none;
        border-left: 3px solid rgb(59, 126, 137);
        left: auto;
        right: 270px;
        transform: rotateZ(0deg);
      }

      &.line2 {
        border-left: 3px solid rgb(59, 126, 137);
        border-bottom: 3px solid rgb(59, 126, 137);
        border-right: none;
        border-top: none;
        left: auto;
        right: 270px;
        top: 300px;
        transform: rotateZ(0);
      }


      &.line3 {
        border-left: 3px solid rgb(59, 126, 137);
        border-right: none;
        left: auto;
        right: 270px;
        top: 430px;
        transform: rotateZ(0deg);
      }

      &.line4 {
        border-bottom: 3px solid rgb(59, 126, 137);
        border-left: 3px solid rgb(59, 126, 137);
        border-right: none;
        left: auto;
        right: 270px;
        top: 620px;
        transform: rotateZ(0deg);
      }

      &.line5 {
        left: auto;
        right: 500px;
        top: 220px;
        border-right: none;
        border-left: 3px solid rgb(59, 126, 137);;
        transform: rotateZ(0deg);
      }

      &.line6 {
        border-right: none;
        border-left: 3px solid rgb(59, 126, 137);
        left: auto;
        right: 500px;
        top: 460px;
        transform: rotateZ(0deg);
      }
    }
  }

  .line {
    position: absolute;

    left: 0;
    height: 30px;
    width: 120px;

    &.line1 {
      border-top: 3px solid rgb(59, 126, 137);
      border-right: 3px solid rgb(59, 126, 137);
      left: 270px;
      top: 110px;
      transform: rotateZ(0deg);
    }

    &.line2 {
      border-bottom: 3px solid rgb(59, 126, 137);
      border-right: 3px solid rgb(59, 126, 137);
      left: 270px;
      top: 300px;
      transform: rotateZ(0deg);
    }

    &.line3 {
      border-top: 3px solid rgb(59, 126, 137);
      border-right: 3px solid rgb(59, 126, 137);
      left: 270px;
      top: 430px;
      transform: rotateZ(0deg);
    }

    &.line4 {
      border-bottom: 3px solid rgb(59, 126, 137);
      border-right: 3px solid rgb(59, 126, 137);
      left: 270px;
      top: 620px;
      transform: rotateZ(0deg);
    }

    &.line5 {
      border-top: 3px solid rgb(59, 126, 137);
      border-right: 3px solid rgb(59, 126, 137);
      left: 500px;
      top: 220px;
      height: 70px;
      transform: rotateZ(0deg);
    }

    &.line6 {
      border-bottom: 3px solid rgb(59, 126, 137);
      border-right: 3px solid rgb(59, 126, 137);
      left: 500px;
      top: 460px;
      height: 70px;
      transform: rotateZ(0deg);
    }

  }

  .match-block {
    font-size: 20px;

    .team-name {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .group-table {
    position: relative;
    left: 0;
    top: 0;

    //padding: 0 100px;
    width: 100%;

    .row-score-status {
      color: #ff2b2b;
      font-size: 20px;
      font-weight: 400;
      //text-shadow: 1px 1px 5px #000;
      text-align: right;
    }

    .row-time {
      font-size: 26px;
      display: flex;

      .timezone-time {
        padding-left: 5px;
      }
    }

    .timezone-time {
      padding-left: 5px;
    }

    .row-group {
      position: absolute;
      left: -70px;
      font-size: 52px;
      font-weight: 300;
      color: white;
      border: 2px solid #d2f95a;
      border-radius: 100%;
      padding: 10px;
      width: 70px;
      height: 70px;
      line-height: 0.9;
      text-align: center;
    }


    .group-row {
      display: flex;
      //width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      //flex-basis: 50%;
      margin-bottom: 25px;
      line-height: 1;
      //flex-direction: column;
      position: relative;

      &:nth-of-type(even) {
        align-items: flex-end;

        .match-block {
          .row-score {
            align-items: flex-start;
            right: auto;
            left: 30px;
          }


          .team-home {
            margin-left: auto;
          }

          .team-away {
            margin-left: auto;
          }

          .row-score-status {
            left: 20px;
            right: auto;
          }
        }
      }

      //& > div {
      //  flex: 1;
      //}
    }

    .row-score {
      width: 115px;
    }

    .group-label {
      font-size: 50px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 25px;
      //text-decoration: underline;
    }

    .group-content {
      font-size: 50px;
      text-transform: uppercase;
      font-weight: 700;
    }

    .group-rows {
      display: flex;
      width: 100%;
      //flex-direction: column;
      flex-wrap: wrap;
      position: relative;
      flex-direction: column;

      &.stage-8thFinals {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        padding: 0 50px;

        .group-row-index-4, .group-row-index-5, .group-row-index-6, .group-row-index-7 {
          justify-content: flex-end;
        }

        .group-row {
          margin-bottom: 40px;

          //&:last-child {
          //  margin-bottom: 0;
          //}
        }


        //ORDERING
        //ENG
        //.group-row-index-3 {
        //  order: 7;
        //  @extend %match-left;
        //}
        //
        ////ARG
        //.group-row-index-1 {
        //  order: 5;
        //  @extend %match-left;
        //}
        //
        ////PL
        //.group-row-index-2 {
        //  order: 3;
        //  @extend %match-left;
        //  // margin-bottom: 45px;
        //}
        //
        ////NL
        //.group-row-index-0 {
        //  order: 1;
        //  @extend %match-left;
        //}
        //
        ////marocco
        //.group-row-index-6 {
        //  order: 8;
        //  @extend %match-right;
        //}
        //
        ////Japan
        //.group-row-index-4 {
        //  order: 6;
        //  @extend %match-right;
        //}
        //
        ////portugal
        //.group-row-index-7 {
        //  order: 4;
        //  @extend %match-right;
        //}
        //
        ////brasil
        //.group-row-index-5 {
        //  order: 2;
        //  @extend %match-right;
        //}
      }

      &.stage-Quarter-finals {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        padding: 0 280px;
        margin: auto;
        height: 535px;
        row-gap: 160px;

        //height: 50%;

        //.group-row {
        //  flex-basis: 50%;
        //}

        .group-row-index-2, .group-row-index-3 {
          justify-content: flex-end;
        }

        .group-row {
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        //ORDERING
        //ENG / FRA
        //.group-row-index-3 {
        //  order: 4;
        //  @extend %match-right;
        //}
        //
        //.group-row-index-1 {
        //  order: 3;
        //  @extend %match-left;
        //}
        //
        //.group-row-index-2 {
        //  order: 2;
        //  @extend %match-right;
        //}
        //
        //.group-row-index-0 {
        //  order: 1;
        //  @extend %match-left;
        //}
      }

      &.stage-Semi-finals {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        padding: 0 510px;
        margin: auto;
        height: 220px;

        .group-row-index-1 {
          justify-content: flex-end;
        }

        //ORDERING
        //ENG / FRA
        .group-row-index-1 {
          //order: 1;
          @extend %match-right;
        }

        .group-row-index-0 {
          //order: 2;
          @extend %match-left;

        }
      }

      &.stage-Final {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        z-index: 10;
        top: -60px;

        justify-content: center;

        .final-caret {
          font-size: 36px;
        }

        //.team-home, .team-away {
        //  width: 82px;
        //  height: 62px;
        //}

        .row-score {
          font-size: 60px;
        }

        .row-score-status {
          right: 28px;
        }


        .group-row {
          flex-basis: auto;
          justify-content: center;
          align-items: center;
        }

        .match-block {
          width: 300px;
          padding: 18px 10px;
        }

        .match-label-final {
          text-align: center;
          text-transform: uppercase;
          font-size: 35px;
          margin-top: 8px;
          margin-bottom: 26px;

          .green-glow {
            font-weight: 400;
          }
        }
      }

      &.stage-3rdPlaceFinal {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        top: 450px;

        .match-label {
          .green-glow {
            font-size: 30px;
            font-weight: 400;
          }
        }

        .row-score {
          font-size: 60px;
        }

        .row-score-status {
          right: 27px;
        }

        .group-row {
          flex-basis: auto;
          justify-content: center;
          align-items: center;
        }

        .match-block {
          width: 250px;
        }

        .match-label {
          text-align: center;
          font-size: 35px;
          margin-top: 12px;
          margin-bottom: 12px;
        }
      }
    }

  }

  .qatar-logo {
    position: absolute;
    top: 55px;
    right: 50px;
    z-index: 10;
    width: 280px;
    margin: auto;
    filter: drop-shadow(2px 4px 6px black);
  }

  .logo-competition-wrap {
    position: absolute;
    top: 160px;
    left: 0;
    right: 0;
    width: 220px;
    height: 220px;
    margin: auto;
  }

  .logo-competition {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .team-home, .team-away {
    width: 45px;
    height: 30px;
    //background-color: #2A4354;
    border: 2px solid rgb(59, 126, 137);
    color: rgb(59, 126, 137);
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;

    &.no-flag-border {
      border: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .team-name {
    flex: 1;
    margin: 0px 8px;
    font-size: 15px;
  }

  .team-logo-container {
    position: absolute;
    z-index: 5;
    left: 60px;
    top: 350px;
    width: 340px;

  }


  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .wc-content {
    position: absolute;
    width: 1800px;
    height: 900px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
  }

  .blink-me {
    animation: blink 1s linear infinite;
  }

  @keyframes blink {
    from {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    to {
      visibility: visible;
    }
  }

}
