.sentence {
  top: 1015px;
}

.teams-block {

  .team-logo-container {
    width: 300px;
    height: 300px;
  }

  .team-logo-container.home {
    left: 270px;
    top: 640px;
  }

}


.custom-background-container {
  -webkit-mask: linear-gradient(0deg, rgba(0, 0, 0, 0) 35%, black 55%);

  .custom-background {
    //height: 100%;
    //width: 100%;
    //object-fit: contain;
    //object-position: right;
    height: 100%;
    position: absolute;
    right: 0;
  }
}
