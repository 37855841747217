/*
 * T_wc_attacking_zones
 */

$className: T_wc_attacking_zones;

.T_wc_attacking_zones.animation {

  .stat-row, .team-logo-container, .versus-info, .comp-arrow-container {
    opacity: 0;
  }

}
