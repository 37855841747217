/*
 * T_wc_goal_overview
 */
$className: T_wc_goal_overview;

.#{$className}.animation {

  .highlight, .goal, .goals, .goal-shots, .subheadline, .main-header, .insight-zone-logo {
      opacity: 0;
  }

  .highlight {
    top: 0;
  }

}
