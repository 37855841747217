/*
 * T_staticslide
 */

 $className: preview-box-container;

 .#{$className} {
   font-family: "Predator-Sans-Rounded";
   -webkit-font-smoothing: antialiased;
 
   .static-slide-box-container {
     opacity: 0;
   }
 
   .static-slide-box-left {
     position: absolute;
     width: 540px;
     height: 500px;
     z-index: 1;
     top: 290px;
     left: 120px;
     padding: 20px;
   }
 
   .static-slide-box-right {
     position: absolute;
     right: 120px;
     width: 540px;
     height: 500px;
     top: 290px;
     z-index: 1;
     padding: 20px;
   }
 
   .template-background {
     width: 100%;
     height: 100%;
     object-fit: cover;
   }
 
   .static-slide-box-no {
     display: none;
   }
 
   .static-slide-main-box {
     background-color: #0d4d5c;
     position: relative;
     z-index: 2;
     width: 100%;
     height: 100%;
     color: white;
     
     .qatar-logo {
       width: 70%;
       padding-left: 40px;
       padding-top: 40px;
       img {
         width: 100%;
       }
     }
 
     .heimspiel-country-container {
       display: flex;
       margin-bottom: 36px;
       align-items: center;
       font-size: 10px;
       margin-top: 96px;
       margin-left: 40px;
       .heimspiel-country {
         width: 100px;
         margin-right: 24px;
   
         img {
           width: 100%;
           height: 100%;
           padding: 0;
         }
       }
       .heimspiel-info-country {
         font-size: 22px;
         font-weight: bold;
         margin-right: 12px;
       }
   
       .heimspiel-info-discipline {
         font-size: 20px;
         font-weight: 400;
   
       }
       .heimspiel-info {
         display: flex;
         flex-direction: column;
         justify-content: center;
       }
   
     }
 
     .box-content-container {
       padding-left: 40px;
       padding-right: 40px;
     }
 
     .box-title-green {
       font-size: 52px;
       color: #d2f95a;
     }
     .box-desc {
       margin-top: 24px;
       font-size: 22px;
     }
   }
 }
 