.score-container {
  top: 1160px;
  left: 0;
  font-size: 320px;
}

.custom-background-container {
  width: 1920px;
  height: auto;
}

.periods-container {
  left: 0;
  top: 1190px;
  right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.teams-block {
  position: absolute;
  top: 1310px;
  width: 970px;
  margin: auto;
  left: 0;
  right: 0;

  .home, .away {
    width: 210px;
    height: 210px;
  }

  .home {
    top: 0px;
    left: 0;
  }

  .away {
    top: 0px;
    right: 0;
  }
}

.home-scorers {
  left: 50px;
  right: auto;
  bottom: 800px;
  min-height: 500px;
  //min-width: 100%;
  white-space: nowrap;
  align-items: flex-start;
}

.away-scorers {
  right: 50px;
  left: auto;
  bottom: 800px;
  min-height: 500px;
  align-items: flex-end;
}

.score-away, .score-home {
  width: 205px;
}
