/*
 * T_wc_table
 */

$className: T_wc_table;

.T_wc_table.animation {
  .qatar-logo, .group-label, .group-content > div {
    opacity: 0;
  }
}
