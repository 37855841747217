/*
 * T_adlerresults
 */

$className: T_wc_static_facts;

.#{$className}.animation {

  .left-block-side, .right-block-side {
    opacity: 0;
  }



}

.app-opta {
  .#{$className}.animation {
    .comp-match-score-widget {
      opacity: 1;
    }
  }
}
