$className: T_wc_goal_overview;

$leftSideWidth: 575px;
$contentWidth: 1750px;

.#{$className} {
  font-family: "Rubik";
  -webkit-font-smoothing: antialiased;

  .content {
    position: relative;
    height: 100%;
  }

  .team-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 180px;
    max-height: 140px;
    object-position: center;
  }

  .left-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: $leftSideWidth;
    height: 100%;
    background-color: #0d4d5c;
  }

  .right-block-side {
    position: absolute;
    right: 0;
    width: $contentWidth - $leftSideWidth;
    height: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }

  .custom-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .header {
    display: flex;
    padding-top: 60px;
    padding-bottom: 35px;

    justify-content: space-between;

    .main-header {
      font-size: 51px;
      font-weight: 500;
    }

    .insight-zone-logo {
      padding-top: 10px;
    }
  }

  .subheadline {
    font-size: 32px;
    padding-bottom: 45px;
  }

  .comp-match-score-widget {
    position: relative;
  }

  .goals {
    margin: 50px 10px;
    position: relative;
  }

  .goal-line {
    display: flex;
    padding-top: 20px;
    padding-bottom: 25px;
    font-size: 24px;
    height: 75px;
    align-items: center;
    line-height: 1;

    &.own-goal {
      color: #f32821;

      .name {
        color: #f32821;
      }
    }

    &.highlight {
      background-color: #4ea1af;
      margin-top: -75px;

      position: absolute;
      margin-left: -10px;
      width: 575px;
      height: 75px;
      padding: 0;
      transition: top 0.4s ease-in-out;

      top: 75px;
    }

    .name {
      z-index: 5;
    }

    .time {
      font-weight: 300;
      padding-right: 10px;
      padding-left: 10px;
      z-index: 5;
    }

    .home, .away {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.1;
    }

    .home {
      text-align: left;
      flex-basis: 45%;

      text-align: right;
      z-index: 5;
    }

    .score {
      text-align: center;
      flex-basis: 10%;
      color: #d2f95a;
      text-align: center;
      z-index: 5;

    }

    .away {
      text-align: right;
      flex-basis: 45%;
      text-align: left;
      z-index: 5;
    }

  }

  .goal-shots {
    display: flex;
    height: 256px;
    justify-content: space-between;
    align-items: center;

    img.country-flag {
      padding: 0;
    }

    &:first-child {
      margin-bottom: 130px;
    }

    .goalmouth {
      height: 100%;
      position: relative;
    }

    .goal {
      position: absolute;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #75d418;

      &.own-goal {
        background-color: #f32821 !important;
      }
    }
  }

  .team-name {
    font-size: 24px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 10px;
  }



  .team-logo-container {
    justify-content: center;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

}
