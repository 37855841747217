.comp-arrow-container {

  width: 376px;
  position: relative;
  //width: 33.33%; /* Width of the arrow body */
  height: 150px; /* Height of the arrow body */
  margin: 0 10px; /* Space between arrows */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white; /* Color of the text */
  font-weight: 400;
  font-size: 80px;
  align-self: end;
  text-align: center;
  flex-direction: column;
  line-height: 0;

  & span {
    z-index: 1; /* Ensures the text is above the triangle */
  }

  .comp-arrow {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: #a9d18e;


    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 188px solid transparent; /* Half the width of the rectangle */
      border-right: 188px solid transparent; /* Half the width of the rectangle */
      border-bottom: 120px solid #a9d18e; /* The color of the arrow, creates the triangle */
      transform: translateX(-50%) translateY(-100%);
      top: 1px;
    }

    &.ghost-2, &.ghost-3 {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }

    #leftZone {

    }
  }

  &#leftZone {
    .ghost-1 {
      z-index: 3;
      background-color: #9BBE4B;

      &:after {
        border-bottom: 120px solid #9BBE4B;
      }
    }

    .ghost-2 {
      z-index: 2;
      top: -40px;
      background-color: #B4D953;

      &:after {
        border-bottom: 120px solid #B4D953;
      }
    }

    .ghost-3 {
      z-index: 1;

      top: -80px;
      background-color: #D1F85A;


      &:after {
        border-bottom: 120px solid #D1F85A;
      }
    }
  }

  &#centralZone {
    .ghost-1 {
      z-index: 3;
      background-color: #588491;

      &:after {
        border-bottom: 120px solid #588491;
      }
    }

    .ghost-2 {
      z-index: 2;
      top: -40px;
      background-color: #6796A3;

      &:after {
        border-bottom: 120px solid #6796A3;
      }
    }

    .ghost-3 {
      z-index: 1;

      top: -80px;
      background-color: #78ABB8;


      &:after {
        border-bottom: 120px solid #78ABB8;
      }
    }
  }

  &#rightZone {
    .ghost-1 {
      z-index: 3;
      background-color: #A2BA78;

      &:after {
        border-bottom: 120px solid #A2BA78;
      }
    }

    .ghost-2 {
      z-index: 2;
      top: -40px;
      background-color: #BCD486;

      &:after {
        border-bottom: 120px solid #BCD486;
      }
    }

    .ghost-3 {
      z-index: 1;

      top: -80px;
      background-color: #DAF198;


      &:after {
        border-bottom: 120px solid #DAF198;
      }
    }
  }

}


