/*
 * T_wc_playercompare
 */

$className: T_wc_playercompare;

.T_wc_playercompare.animation {
  .stat-row, .player-away, .player-home, .versus-info {
    opacity: 0;
  }

}
