.alarm-container {
  height: 100%;
  position: relative;

  .child-template-container {
    height: 100%;
    position: absolute;
    z-index: 50;
    top: 0;
  }
}

.timeline-container {

  .template-container {
    overflow: hidden;

    &.active-slide {
      .comp-template-block {
        opacity: 0;
      }
    }

    &.next-slide {
      .comp-template-block {
        opacity: 0;
      }
    }
  }
}
