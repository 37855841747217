/*
 * T_adlersentence
 */

$className: T_adlersentence;

.#{$className}.animation {

  .teams-block {
    display: block;

    img {
      opacity: 0;
    }
  }

}
