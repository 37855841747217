.score-container {
  top: 950px;
  left: 0;
  font-size: 280px;

  > div {
    margin: 0px 351px;
  }
}

.custom-background-container {
  width: 1920px;
  height: auto;
}

.periods-container {
  left: 0;
  top: 950px;
  right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;

  > div {
    font-size: 30px;
  }
}

.teams-block {
  position: absolute;
  top: 1060px;
  width: 1080px;
  margin: auto;
  left: 0;
  right: 0;

  .home, .away {
    width: 240px;
    height: 240px;
  }

  .home {
    top: 0px;
    left: 0;
  }

  .away {
    top: 0px;
    right: 0;
  }
}

.home-scorers {
  right: auto;
  left: 50px;
  bottom: 430px;
  min-height: 500px;
  //min-width: 100%;
  white-space: nowrap;
  align-items: flex-start;
}

.away-scorers {
  right: 50px;
  left: auto;
  bottom: 430px;
  min-height: 500px;
  align-items: flex-end;
}

.score-away, .score-home {
  width: 200px;
}


.triangle1 {
  width: 110px;
}
