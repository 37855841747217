/*
 * T_teamcompare
 */
.T_teamcompare {

  .header {
    position: absolute;
    top: 55px;
    margin: auto;
    left: 0;
    right: 0;
    width: 1440px;

    .score-vs-block {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 260px;
    }

    .score, .vs {
      text-align: center;
      color: black;


      line-height: 260px;
      font-size: 207px;
    }

    .home-team-block {
      justify-content: flex-end;
      left: 0;
    }

    .away-team-block {
      justify-content: flex-start;
      right: 0;
    }

    .home-team-block, .away-team-block {
      height: 260px;
      text-transform: uppercase;
      width: 515px;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      font-size: 36px;
      background-color: rgba(0, 0, 0, 0.35);
    }

    .AwayTeamLogo, .HomeTeamLogo {
      height: inherit;
      width: 100%;
      padding: 0 25px;
      box-sizing: border-box;
      background-color: black;
      text-align: center;

      img, video {
        height: 140px;
        display: block;
        margin: 25px auto 20px;
      }
    }

    .AwayTeamLogo {

    }

    .HomeTeamLogo {

    }

    .AwayTeamName, .HomeTeamName {
      font-size: 48px;
      line-height: 1;
    }


    .headline-block {
      text-transform: uppercase;
      color: black;
      font-size: 50px;
      width: 100%;
      position: absolute;
      top: 260px;
      height: 70px;
      left: 0;
      text-align: center;
    }

  }


  .player {
    img {
      width: 660px;
      display: block;
    }

    .player-img {
      z-index: 15;
    }

    &.player1 {
      .player-img {
        left: -75px;
      }
    }

    &.player2 {
      .player-img {
        right: -75px;
      }
    }


    .player-img {
      position: absolute;
      bottom: 0;
    }


  }


  .stats-block {
    position: absolute;
    top: 396px;
    width: 1440px;
    /* width: 1810px; */
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    right: 0;
    height: 620px;
    margin: auto;
    box-sizing: border-box;

    .StatRow {
      margin: auto;
      width: 1290px;
      height: 100px;
      position: relative;
      margin-bottom: 10px;
    }

    .StatRow:first-child {
      margin-top: 25px;
    }

    .stat-home-value, .stat-away-value {
      position: absolute;
      top: 0;
      font-size: 60px;
    }

    .stat-home-value {
      left: -330px;
    }

    .stat-away-value {
      right: -330px;
    }

    .stat-name {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 32px;
      top: 15px;
      line-height: 1.1;
      width: 460px;
      margin: auto;
      height: 65px;
      display: flex;
      /* overflow: hidden; */
      align-items: center;
      justify-content: center;
    }

    .stat-name-content {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .stat-away-percent, .stat-home-percent {
      position: absolute;
      top: 60px;
      font-size: 40px;
      display: none;
    }

    .stat-away-percent {
      right: 0;
    }

    .stat-home-percent {
      left: 0;
    }

    $statLineWidth: 540px;

    .stat-line-block {
      position: absolute;
      width: $statLineWidth;
      height: 8px;
      top: 90px;
      right: 52%;

      .stat-line-fill-overflow {
        z-index: 2;
        width: 0;


        .line-part {
          background-color: deepskyblue;
        }
      }

      .stat-line-empty-overflow {
        z-index: 1;
        width: 100%;

        .line-part {
          background-color: orangered;
          opacity: 0.3;
        }
      }

      .stat-line-fill-overflow, .stat-line-empty-overflow {
        position: absolute;
        top: 0;
        right: 0;
        height: inherit;
        overflow: hidden;

        .stat-line-fill, .stat-line-empty {
          display: flex;
          height: inherit;
          width: calc($statLineWidth);
          position: absolute;
          top: 0;
          right: 0;
        }

        .line-part {
          height: inherit;
          margin-left: 6px;
          width: calc($statLineWidth / 10);
        }

        .line-part:first-child {
          margin-left: 0;
        }
      }


      &.stat-line-block-away {
        left: 52%;

        .stat-line-fill-overflow {
          left: 0;
          right: auto;
        }

        .stat-line-fill, .stat-line-empty {
          right: auto;
          left: 0;
        }
      }
    }
  }


}
