@import './formats.scss';

html, body {
  overflow: hidden;
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  //background-color: black;
  color: white;
}

.template-container {
  color: white;
}

.loader-container {
  //text-align: center;
  //margin-top: 100px;
  //color: black;
  //
  //& > span {
  //  font-size: 120px;
  //}
}

.root-container {
  width: 100%;
  height: 100%;

  & > div {
    overflow: hidden;
    position: relative;
    width: inherit;
    height: inherit;
  }
}

.video-mask-green, .video-mask-blue {
  position: relative;
  width: 100%;
  height: 100%;
  //
  //width: 200px;
  //height: 200px;
}

.video-mask-blue {

  background-color: blue;
  z-index: 9000;
}

.video-mask-green {
  background-color: green;
  z-index: 9000;
  display: none;
}

//.timeline-container {
//  color: white;
//}

.timeline-main-bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.timeline-transition-video {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
}

.debug-Info {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5555;
  background: rgba(0, 0, 0, 0.2);
  padding: 5px;
  line-height: 1;
  border-radius: 5px;
}

.format-not-supported {
  color: #85000a;
  font-size: 26px;
}

//clients
@import './clients/app-adler';
@import './clients/app-opta';
