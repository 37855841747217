/*
 * T_adlertable
 */

$className: T_adlertable;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;

  .team-logo-container {
    position: absolute;
    width: 130px;
    height: 130px;
    top: 104px;
    left: 19px;
    transform: rotateZ(
        -22deg);

    img {
      transform: rotateZ(
          22deg);
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: relative;
    }
  }


  .headline {
    font-family: "Predator-Sans-Cut";
    font-size: 115px;
    position: absolute;

    text-align: center;
    //left: -30px;
    width: 100%;
    font-style: italic;

    div {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 100%;
      z-index: 2;

      &.main-line {
        letter-spacing: 0.015em;
        left: 2px;
        position: relative;
      }

      &.ghost-line {
        color: #cc0a1a;
        z-index: 1;
        letter-spacing: 0
      }
    }
  }

  &.side-away {
    .headline {
      div {
        &.ghost-line {
          color: #054888;
        }
      }
    }
  }

  .sponsors {
    position: absolute;

    &.sponsor-sap {
      right: 55px;
      top: 55px;
    }
  }

  .double-line-top, .double-line-bottom {
    height: 8px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-image-slice: 1;
    border-image-source: radial-gradient(circle, #cc0a1a 0%, transparent 100%);
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 2000px;
    margin: auto;
    right: 0;
  }

  .double-line-bottom {
    top: 52px;
  }

  .standing-table {
    display: flex;
    position: absolute;
    /* width: 1430px; */
    left: 0;
    width: 100%;
    right: 0;
    margin: auto;
    top: 20px;
    z-index: 10;
    font-size: 32px;
    text-transform: uppercase;
    height: 100%;
    align-items: center;
    padding-top: 160px;
    justify-content: center;

    .table {
      //overflow: hidden;
      position: relative;
      height: 942px;

      //-webkit-mask: linear-gradient(360deg, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 1) 10%);

    }

    .table-team-logo {
      width: 50px;
      height: 50px;
      object-fit: contain;
      object-position: center;
      margin: auto;
    }

    .row {
      display: flex;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      //padding-left: 40px;
      padding: 0 180px;

      &.expose-selected-row {
        color: #E40521;
      }

      .extra-line {
        height: 2px;
        width: 1700px;
        position: absolute;
        left: 0;
        bottom: -20px;
        background-color: #fff;
        backface-visibility: hidden;
        transform-style: preserve-3d;
      }

      .cell {
        position: relative;
        backface-visibility: hidden;
        transform-style: preserve-3d;

        &.tor-diff {
          span {
            width: 60px;
            display: inline-block;
            text-align: center;
            white-space: nowrap;
          }
        }
      }

      &.page-1 {
        //opacity: 0;
        display: none;
      }

      &.header-row {
        font-size: 30px;
        line-height: 61px;
        height: 60px;
        color: #fff;
        margin-bottom: 10px;
        //overflow: hidden;
        //display: none;
      }

      &:not(.header-row) {
        height: 53.5px;
        line-height: 1;
        //background-color: rgba(0,0,0, 0.15);
        //background-image: radial-gradient(circle, rgba(0, 0, 0, 0.30) 0%, transparent 100%);

        border-bottom: 2px solid transparent;
        border-image-slice: 1;
        border-image-source: radial-gradient(circle, #ffffff 0%, transparent 100%);

        //&:nth-child(2n) {
        //  //background-color: rgba(255,255,255,0.15);
        //  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.30) 0%, transparent 100%);
        //}

        &:last-of-type {
          border-image-source: none;
        }

        .cell {
          align-self: center;
        }

        .cell:nth-child(1) {
          //color: #dd013f;
          //font-size: 46.6px;
          //border-bottom: 1px solid #dd013f;
          //border-top: 1px solid #dd013f;
          //height: 45px;
          width: 65px;
          text-align: left;

          //line-height: 50px;
          align-self: center;
        }
      }

      //&.header-row .cell {
      //  border-bottom: 2px solid white;
      //}

      .cell:nth-child(1) {
        width: 65px;
        border-bottom: none;
        margin-right: 15px;
      }

      .cell:nth-child(2) {
        width: 67px;
        //margin-left: -15px;
        border-bottom: none;
        text-align: center;
        margin-right: 38px;
      }

      .cell:nth-child(3) {
        width: 440px;
      }

      .cell:nth-child(4) {
        width: 110px;
        text-align: center;
      }

      .cell:nth-child(5) {
        width: 200px;
        text-align: center;
      }

      .cell:nth-child(6) {
        width: 180px;
        text-align: center;
        //margin-right: 60px;
      }

      .cell:nth-child(7) {
        width: 180px;
        text-align: center;
      }

      .cell:nth-child(8) {
        width: 180px;
        text-align: center;
      }
    }


  }
}
