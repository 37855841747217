/*
 * T_wc_seasontotals
 */
$className: T_wc_seasontotals;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .green-glow {
    color: #d2f95a;
  }

  .main-header {
    top: 170px;
    left: 20px;
    font-size: 149px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 10;
  }

  .powered-by {
    position: absolute;
    height: 34px;
    left: 24px;
    top: 600px;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .qatar-logo {
    position: absolute;
    top: -55px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }


  .info-block {
    position: absolute;
    top: 580px;
    left: 100px;
    font-size: 30px;
    font-weight: 400;
  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .main-headline {
    font-size: 80px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    text-align: center;
    width: 100%;
    top: 45px;
    line-height: 1;
    white-space: nowrap;
    filter: drop-shadow(2px 2px 4px black);
    text-transform: uppercase;
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .player {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* justify-items: center; */
    align-items: center;
    width: 640px;
    //height: 100%;

    &.player-home {
      //background-color: #003340;
      //background-color: rgba(0, 51, 64, 0.8);
    }

    &.player-away {
      //background-color: #00293d;
      //background-color: rgba(0, 41, 61, 0.8);
    }

    .trikot {
      position: relative;

      .player-number {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 100px;
        top: 170px;
        font-weight: 400;
        letter-spacing: -4px;
      }

      .player-position {
        font-size: 24px;
        font-weight: 400;
        text-align: center;
      }

      img {
        width: 330px;
        filter: invert(1);
        margin-top: 60px;
        margin-bottom: 10px;
      }

    }

    .player-name {
      margin-top: 10px;
      line-height: 1.15;
      font-size: 55px;
      text-align: center;
      margin-bottom: 20px;
      text-transform: uppercase;

      .player-firstname {
        font-size: 45px;
      }

      .player-lastname {
        font-weight: 800;
      }
    }
  }

  .mvp-block {
    position: absolute;
    width: 1080px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #002439;
  }


  .teams-score {
    display: flex;
    position: absolute;
    /* right: 221px; */
    top: 150px;
    width: 100%;
    //left: 420px;
    justify-content: center;
    text-transform: uppercase;

    .stats-type {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      font-weight: 700;
      line-height: 1.1;
      filter: drop-shadow(2px 2px 4px black);

      .separator {
        margin: 0 5px;
        margin-bottom: 5px;
      }
    }


    .team-logo-container {
      //position: absolute;
      z-index: 5;
      //left: 60px;
      //top: 350px;
      width: 80px;

      &.home {
        margin-right: 30px;
      }

      &.away {
        margin-left: 30px;
      }
    }
  }


  .team-stats {
    position: absolute;
    left: 65px;
    top: 216px;
    font-size: 32px;
    width: 950px;

    .stat-label {
      text-transform: uppercase;
      font-weight: 400;
    }

    .stat-row {
      display: flex;
      align-items: center;
      border-top: 3px solid #015b77;
      padding: 10px 40px;
      justify-content: space-between;

      .stat-away, .stat-home {
        font-size: 35px;
      }

      &:last-child {
        border-bottom: 3px solid #015b77;
      }
    }
  }


  .venue-container {
    text-transform: uppercase;
  }
}
