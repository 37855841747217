/*
 * .T_playercompare
 */
.T_playercompare {

  .header {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;

    .home-team-block {
      justify-content: flex-end;
      left: 55px;
      height: 120px;
    }

    .away-team-block {
      justify-content: flex-start;
      right: 55px;
      height: 120px;
    }

    .home-team-block, .away-team-block {
      text-transform: uppercase;
      width: 900px;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      font-size: 36px;
      background-color: rgba(0, 0, 0, 0.35);
    }

    .AwayTeamLogo, .HomeTeamLogo {
      height: inherit;
      padding: 10px 25px;
      box-sizing: border-box;
      background-color: black;
      max-width: 230px;

      img, video {
        height: 100%;
      }

      video {
        width: 100%;
      }

    }

    .AwayTeamLogo {
      margin-right: 10px;
    }

    .HomeTeamLogo {
      margin-left: 10px;
    }


    .home-player-block, .away-player-block {
      width: 900px;
      position: absolute;
      top: 130px;
      height: 180px;
    }

    .home-player-block {
      left: 55px;

    }

    .away-player-block {
      right: 55px;
    }


    .HomePlayerNumber, .AwayPlayerNumber {
      background-color: black;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 85px;
      line-height: 180px;
      font-size: 55px;
      text-align: center;
    }

    .AwayPlayerNumber {
      right: 0;
      left: auto;
    }


    .HomePlayerName, .AwayPlayerName, .HomePlayerPosition, .AwayPlayerPosition {
      font-size: 50px;
      text-transform: uppercase;
      left: 0;
    }

    .AwayPlayerName, .AwayPlayerPosition {
      right: 95px;
      left: 0;
    }

    .HomePlayerName, .HomePlayerPosition {
      right: 0;
      left: 95px;
      text-align: right;
    }

    .HomePlayerName, .AwayPlayerName {
      position: absolute;
      background-color: white;
      color: black;
      height: 90px;
      line-height: 90px;
      padding: 0 15px;
    }

    .HomePlayerPosition, .AwayPlayerPosition {
      position: absolute;
      background-color: black;
      top: 90px;
      height: 90px;
      line-height: 90px;
      padding: 0 15px;
    }

  }


  .player {
    position: absolute;
    bottom: 82px;
    z-index: 15;
    width: 400px;
    height: 600px;
    padding: 2px;
    border: 5px solid transparent;
    //display: flex;
    //align-items: center;

    img, video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    &.player.home {
      left: 80px;
    }

    &.player.away {
      right: 80px;
    }

  }


  .stats-block {
    position: absolute;
    top: 375px;
    /* width: 1810px; */
    background-color: rgba(0, 0, 0, 0.8);
    left: 55px;
    right: 55px;
    height: 645px;
    box-sizing: border-box;

    .StatRow {
      margin: auto;
      width: 920px;
      height: 100px;
      position: relative;
      margin-bottom: 10px;
    }

    .StatRow:first-child {
      margin-top: 25px;
    }

    .stat-home-value, .stat-away-value {
      position: absolute;
      top: 10px;
      font-size: 60px;
    }

    .stat-home-value {
      left: 100px;
    }

    .stat-away-value {
      right: 100px;
    }

    .stat-name {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 32px;
      bottom: 28px;
      line-height: 1.1;
      width: 460px;
      margin: auto;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .stat-away-percent, .stat-home-percent {
      position: absolute;
      top: 60px;
      font-size: 40px;
      display: none;
    }

    .stat-away-percent {
      right: 0;
    }

    .stat-home-percent {
      left: 0;
    }

    $statLineWidth: 315px;

    .stat-line-block {
      position: absolute;
      width: $statLineWidth;
      height: 8px;
      top: 90px;
      right: 52%;

      .stat-line-fill-overflow {
        z-index: 2;
        width: 0;


        .line-part {
          background-color: deepskyblue;
        }
      }

      .stat-line-empty-overflow {
        z-index: 1;
        width: 100%;

        .line-part {
          background-color: orangered;
          opacity: 0.3;
        }
      }

      .stat-line-fill-overflow, .stat-line-empty-overflow {
        position: absolute;
        top: 0;
        right: 0;
        height: inherit;
        overflow: hidden;

        .stat-line-fill, .stat-line-empty {
          display: flex;
          height: inherit;
          width: $statLineWidth;
          position: absolute;
          top: 0;
          right: 0;
        }

        .line-part {
          height: inherit;
          margin-left: 6px;
          width: calc($statLineWidth / 10);
        }

        .line-part:first-child {
          margin-left: 0;
        }
      }


      &.stat-line-block-away {
        left: 52%;

        .stat-line-fill-overflow {
          left: 0;
          right: auto;
        }

        .stat-line-fill, .stat-line-empty {
          right: auto;
          left: 0;
        }
      }
    }
  }


}
