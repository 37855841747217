/*
 * T_wc_teamcompare
 */

$className: T_wc_teamcompare;

.T_wc_teamcompare.animation {

  .stat-row, .team-logo-container, .versus-info {
    opacity: 0;
  }

}
