.app-adler {
  color: rgba(0, 0, 0, .85);

  .comp-MatchDayPopup {
    .teams-block {
      [data-id]:not([data-id="2"]) {
        display: none;
      }
    }
  }
}
