/*
 * T_adlersentence
 */

$className: T_adlersentence;

.#{$className} {
  font-family: "Predator-Sans-Cut";
  -webkit-font-smoothing: antialiased;
  color: #fff;

  .custom-background-overflow {
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
  }

  .custom-background-opacity {

  }

  .custom-background-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    mix-blend-mode: soft-light;
    -webkit-mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 12%, black 70%);

    .custom-background {
      height: 100%;
      width: 100%;
      object-fit: contain;
      //object-position: right;
      //height: 100%;
      position: absolute;
      //right: 0;
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      right: 49px;
      width: 162px;
      top: 45px;
    }

    &.sponsor-sap {
      left: 50px;
      width: 180px;
      top: 45px;
    }
  }

  .sentence {
    position: absolute;
    top: 440px;
    line-height: 1.2;
    font-size: 125px;
    width: 100%;
    left: 75px;
    white-space: nowrap;
    z-index: 10;
  }

  .teams-block {
    top: 0;
    display: none;

    .team-logo-container {
      width: 250px;
      height: 250px;
    }

    .team-logo-container.home {
      left: 270px;
      top: 460px;
      position: absolute;
      z-index: 10;
    }

    .team-logo-container.away {
      display: none;
    }

  }
}

.f-1080x1920 {
  .#{$className} {
    @import "./style-1080x1920";
  }
}
