/*
 * T_wc_customranking
 */

$className: T_wc_customranking;

.T_wc_customranking.animation {
  .table-head, .table-row, .teilnahmen-note {
    opacity: 0;
  }

}
