/*
 * T_wc_playercompare_season
 */

$className: T_wc_playercompare_season;

.T_wc_playercompare_season.animation {
  .stat-row, .player-away, .player-home, .versus-info {
    opacity: 0;
  }

}
