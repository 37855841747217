.root-container .f-1920x1080, .f-1920x1080 {
  $width: 1920px;
  $height: 1080px;

  width: $width;
  height: $height;

  .template-container {
    width: $width;
    height: $height;
  }

  .template-container > div:not(.loader-container) {
    width: $width;
    height: $height;
  }
  .alarm-container > div:not(.loader-container) {
    width: $width;
    height: $height;
  }
  .child-template-container > div:not(.loader-container) {
    width: $width;
    height: $height;
  }
}

//adler
.root-container .f-1080x1920, .f-1080x1920 {
  $width: 1080px;
  $height: 1920px;

  width: $width;
  height: $height;

  .template-container {
    width: $width;
    height: $height;
  }

  .template-container > div:not(.loader-container) {
    width: $width;
    height: $height;
  }
  .alarm-container > div:not(.loader-container) {
    width: $width;
    height: $height;
  }
  .child-template-container > div:not(.loader-container) {
    width: $width;
    height: $height;
  }
}

.root-container .f-1200x1500, .f-1200x1500 {
  $width: 1200px;
  $height: 1500px;

  width: $width;
  height: $height;

  .template-container {
    width: $width;
    height: $height;
  }

  .template-container > div:not(.loader-container) {
    width: $width;
    height: $height;
  }
  .alarm-container > div:not(.loader-container) {
    width: $width;
    height: $height;
  }
  .child-template-container > div:not(.loader-container) {
    width: $width;
    height: $height;
  }
}
