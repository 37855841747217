/*
 * T_adlerresults
 */

$className: T_adlerresults;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;

  .custom-background-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    //z-index: 4;

    .custom-background {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }


  .periods-container {
    position: absolute;
    left: 937px;
    top: 527px;
    display: flex;
    justify-content: center;
    height: 440px;
    flex-direction: column;
    color: #fff;

    & > div {
      line-height: 1;
      width: 100px;
      height: 86px;
      //border: 8px solid #fff;
      //border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
    }
  }

  .extra-time-info {
    display: block;
    font-size: 22px;
    text-align: center;
    margin-top: 4px;
  }

  .score-container {
    font-size: 425px;
    position: absolute;
    z-index: 10;
    top: 409px;
    left: 25px;
    width: 100%;


    & > div {
      display: flex;
      justify-content: center;
    }

    .score-separator {
      width: 125px;
      text-align: center;
      position: relative;
    }

    .score-away {
      //left: 17px;
      //top: -2px;
      position: relative;
    }

    .score-home, .score-away {
      width: 300px;
      text-align: center;
      transform-origin: center center;
    }

    .score-home {
      text-align: center;
    }
  }

  .home-scorers, .away-scorers {
    font-family: "Predator-Sans-SemiLight";
    display: flex;
    font-size: 30px;
    font-weight: bold;
    min-height: 300px;
    flex-direction: column;
    justify-content: center;
    bottom: 190px;
    color: #fff;

    & > div {

    }
  }

  .home-scorers {
    position: absolute;
    right: 1285px;
    align-items: flex-end;
  }

  .away-scorers {
    position: absolute;
    left: 1335px;
    align-items: flex-start;
  }

  .teams-block {
    position: absolute;
    top: 876px;
    width: 100%;

    .home, .away {
      position: absolute;
      width: 110px;
      height: 110px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .home {
      top: -55px;
      left: 208px;
    }

    .away {
      top: -55px;
      right: 208px;
    }
  }

  .sponsors {
    position: absolute;
    bottom: 50px;

    &.sponsor-del {
      left: 50px;
      width: 150px;
    }

    &.sponsor-sap {
      right: 49px;
      bottom: 60px;
      //width: 180px;
    }
  }

  .actual-period-0 {
    .period-2-score {
      display: none;
    }

    .period-3-score {
      display: none;
    }

    .period-4-score {
      display: none;
    }
  }

  .actual-period-1, .actual-period-10 {
    .period-2-score {
      display: none;
    }

    .period-3-score {
      display: none;
    }

    .period-4-score {
      display: none;
    }
  }

  .actual-period-2, .actual-period-20 {
    .period-3-score {
      display: none;
    }

    .period-4-score {
      display: none;
    }
  }

  .actual-period-3, .actual-period-30 {
    .period-4-score {
      display: none;
    }
  }

  .stroke-shadow {
    top: -11%;
    right: 0;
    left: 0;
    bottom: 0;
    font-size: 125px;
    position: absolute;
    color: white;
    -webkit-text-fill-color: transparent;
  }


  .box-score {
    position: relative;
    z-index: 6;
  }

  .box-bg {
    display: flex;
    position: absolute;
    z-index: 4;

    /* 2*sin(120/2)*h = w
       h = w / (sin(60)*2)
       h = w * 0.57736
    */
    .triangle1:before {
      content: "";
      display: block;
      padding-top: 66%;
    }

    .triangle1 {
      background: #014389;
      clip-path: polygon(0 50%, 50% 100%, 100% 50%, 50% 0);
      width: 120px;
      display: inline-block;
      margin: 5px;
    }
  }

  &.side-away {
    .triangle1 {
      background: #e60026;
    }
  }

}

.f-1200x1500 {
  .#{$className} {
    @import "./style-1200x1500";
  }
}

.f-1080x1920 {
  .#{$className} {
    @import "./style-1080x1920";
  }
}
