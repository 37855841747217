/*
 * T_wc_livematchday
 */

$className: T_wc_livematchday;

.#{$className}.animation {

  .live-matchdays-container > div, .main-title, .live-matchdays-positioner {
    opacity: 0;
  }

  .headline {
    font-size: 186px;
    top: 355px;
    z-index: 15;
  }

}
