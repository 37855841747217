/*
 * T_wc_country_medals
 */

$className: T_wc_country_medals;

.T_wc_country_medals.animation {

  .table-head, .table-row, .group-label, .main-header, .competition-positioner {
    opacity: 0;
  }

}
