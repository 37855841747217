/*
 * T_wc_attacking_zones
 */

$className: T_wc_goal;

.T_wc_goal.animation {

  .stat-row, .team-logo-container, .versus-info, .subheadline, .main-header, .comp-player-triko {
    opacity: 0;
  }

}
