@font-face {
  font-family: 'Source-Sans-Pro-Regular';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('../fonts/source/SourceSansPro-Regular.ttf') format('ttf'), /* Chrome 26+, Opera 23+, Firefox 39+ */
}

@font-face {
  font-family: 'Source-Sans-Pro-Black';
  font-style: normal;
  font-weight: normal;
  src: local(''),
  url('../fonts/source/SourceSansPro-Black.ttf') format('ttf'), /* Chrome 26+, Opera 23+, Firefox 39+ */
}

@font-face {
  font-family: 'Predator-Sans-Rounded';
  font-style: normal;
  font-weight: normal;
  src: local(''),
  url('../fonts/predator/Predator0316-Sans-Rounded.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/predator/Predator0316-Sans-Rounded.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Predator-Sans-SemiLight';
  font-style: normal;
  font-weight: normal;
  src: local(''),
  url('../fonts/predator/Predator0316-Sans-SemiLight.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/predator/Predator0316-Sans-SemiLight.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
