/*
 * T_teamfacts
 */
.T_teamfacts {
  -webkit-font-smoothing: antialiased;
  line-height: 1;

  .content-block {
    position: absolute;
    width: 1250px;
    right: 40px;
    top: 32px;
    bottom: 32px;
  }

  .headline {
    height: 130px;
    width: 100%;
    color: black;
    font-size: 72px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 40px;
    text-align: right;
    justify-content: flex-end;
    box-sizing: border-box;
  }

  .subheadline {
    font-size: 48px;
    padding-left: 75px;
    padding-top: 50px;
    /* line-height: 1; */
    text-transform: uppercase;
  }

  #photo {
    position: absolute;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    left: 15px;
    top: 200px;
    width: 600px;
    //height: 100%;
    z-index: 10;

    .picture {
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: auto 100%;
      width: 100%;
      height: 100%;
      z-index: 10;
      object-fit: contain;
    }

    .player1, .player2, .player3 {
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: auto 100%;
      width: 100%;
      height: 100%;
      z-index: 10;
      bottom: 0;
      position: absolute;
      margin-left: -115px;
    }

    .player1 {
      left: -150px;
    }

    .player2 {
      z-index: 15;
    }

    .player3 {
      left: 150px;
    }
  }

  .team-head-block {
    position: relative;
    top: 0;
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .team-name {
      text-transform: uppercase;
      font-size: 48px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      margin-right: 10px;
      height: 100%;
      line-height: 145px;
      padding: 0 25px;
      text-align: right;
    }

    .team-logo {
      height: 100%;
      background-color: black;
      padding: 15px 30px;
      width: 200px;

      img {
        display: block;
        height: 100%;
        margin: auto;
      }
    }
  }


  .factsListBg {
    width: 100%;
    height: 714px;
    position: relative;
    bottom: 0;
    background-color: black;
    overflow: hidden;
  }

  .factsList {
    position: relative;
    margin-left: 120px;
    z-index: 15;
    width: 1100px;
    height: 510px;

    font-size: 36px;
    font-weight: normal;
    color: rgb(255, 255, 255);
    line-height: 50px;
    text-align: left;
    margin-top: 80px;

    ul, ol {
      list-style: none;
    }

    .li-wrapper-content {
      width: 1050px;
    }

    //li{list-style-type:none;}
    li:before, p:before {
      //content: '\B7';
      //font-size: 120px;
      line-height: 1;
      vertical-align: top;
      position: absolute;
      left: 10px;
      top: 15px;
      border-top: solid transparent;
      border-bottom: solid transparent;
      border-left: solid #f00;
      border-width: 12px;
      border-left-color: inherit;
      content: '';
      border-left-width: 20px;
    }

    ol {
      li:before, p:before {
        content: counter(step-counter);
        border: none;
        top: -4px;
        font-size: 80px;
      }
    }

    .li-wrapper {
      color: white;
    }

    li, p {
      counter-increment: step-counter;
      position: relative;
      background-repeat: no-repeat;
      background-position: top left;
      margin: 0 0 50px 0px;
      padding-left: 75px;
      margin-left: -50px;
    }
  }

  .mini-lineup {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .field {
    position: relative;
    width: 100%;
    height: 100%;
    left: 15px;
    bottom: 20px;

    .formation > div {
      position: absolute;
      width: 15px;
      border-radius: 100%;
      height: 15px;
    }

    .formation > div:not(.current-player) {
      background-color: rgba(255, 255, 255, 0.4) !important;
    }
  }
}
