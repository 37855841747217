/*
 * T_wc_standings
 */

$className: T_wc_standings;

.#{$className}.animation {
  .qatar-logo, .group-label, .competition-logo-container {
    opacity: 0;
  }

  .group-content {
    opacity: 0;
  }
}
