/*
 * T_wc_topperformers
 */

$className: T_wc_topperformers;

.T_wc_topperformers.animation {

  .table-head, .table-row, .teilnahmen-note, .table-head {
    opacity: 0;
  }
}
