/*
 * T_wc_countrystadiums
 */

$className: T_wc_countrystadiums;

.T_wc_countrystadiums.animation {

  .qatar-logo, .competition-logo-container, .main-headline, .comp-pitch-tactics, .germany-map, .matchday-wrapper {
    opacity: 0;
  }

  .stadium-pos-left, .stadium-pos-right {
    opacity: 0;
  }

  canvas {
    opacity: 0;
  }

  .match-content-container {
    opacity: 0;
  }
}
