/*
 * T_wc_lineup
 */

$className: T_wc_lineup;

.T_wc_lineup.animation {

  .qatar-logo, .main-headline, .pitch-container, .lineup {
    opacity: 0;
  }
}
