/*
 * T_wc_countrystadiums
 */
$className: T_wc_countrystadiums;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;

  .germany-map {
    position: absolute;
    margin: auto;
  }

  
  .header-left {
    font-size: 70px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-top: 30px;
    text-transform: uppercase;
    padding: 0 35px;
  }

  .competition-positioner {
    position: absolute;
    left: 50px;
    top: 50px;
    display: flex;
    width: 150px;
    height: 150px;
    justify-content: center;
  }

  .competition-logo-container {
    height: 150px;
    width: 150px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }


  .header-right {
    font-size: 50px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    line-height: 1;
    padding: 0 20px;
    position: absolute;
    left: 600px;
    margin-top: 35px;
    z-index: 20;
  }


  .country-map {
    position: absolute;
    pointer-events: none;
    z-index: 10;
    left: 0;
    top: 0;
    z-index: 20;
  }

  .green-glow {
    color: #d2f95a;
  }

  .left-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; 
  }
  .matchday-wrapper {
    position: absolute;
    left:0;
    right:0;
    margin: auto;
    text-align: center;
    top: 50px;
    text-transform: uppercase;
    .matchday-title {
      font-size: 45px;
    }

    .matchday-date {
      font-size: 30px;
    }
  }

  .right-block-side {
    position: absolute;
    
    width: 100%;
    height: 100%;
    left: 500px;
    top: 150px;
    .match-content-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: #d2f95a;
      padding: 12px 2px 2px 2px;
      width: 200px;
      .city-name {
        color: black;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 4px;
      }
      .content-section {
        background-color: #0D4B5A;
        padding: 12px;
        .content-time {
          text-align: center;
        }
      }

      

      .team-score-section {
        display: flex;
        justify-content: space-between;
        .team-score {
          font-size: 30px;
          line-height: 1.8;
        }
        .team-info {
          display: flex;
          flex-direction: column;
          width: 60px;
          align-items: center;
          margin-top: 12px;
          img {
            width: 50px;
            height: 30px;
          }
        }
        .live-status {
          bottom: 10px;
          top: auto;
          left: 0;
          right: 0;
          margin: auto;
          font-size: 14px;

          .red-bg {
            padding: 3px 2px;
          }
          .white-bg {
            padding: 2px 2px;
          }

          .circle {
            width: 6px;
            height: 6px;
          }
        }
        .animation-container {
          width: 50px;
          height: 20px;
        }
      }

      
    }

    canvas {
      position: absolute;
    }
    
  }


  .qatar-logo {
    position: absolute;
    top: 50px;
    right: 100px;
    z-index: 10;
    margin: auto;
    width: 320px;

    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .main-header {
    top: 100px;
    left: 100px;
    font-size: 160px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .coach-info {
    position: absolute;
    top: 484px;
    left: 50px;
    font-size: 28px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 600;
    }

  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .team-name {
    font-size: 35px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 5px;
    white-space: nowrap;
  }

  .team-logo-container {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 250px;
    width: 320px;
    height: 260px;
    right: 0;
    margin: auto;
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .venue-container {
    text-transform: uppercase;
  }

  .vs-team-info {
    font-size: 24px;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .lineup {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    text-transform: uppercase;
    width: 100%;

    .lineup-pitch {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 1190px;
      background-color: #015b78;


      .lineup-player {
        .player-name {
          height: 50px;
          overflow: hidden;
          font-weight: 400;
        }
      }

      & > img {
        height: 100%;
        width: 100%;
      }

      .formation-block {
        width: 96%;
      }
    }

    .lineup-content {
      font-size: 30px;
      line-height: 1.4;
    }

    .wc-headline {
      font-size: 70px;
      font-weight: 700;
    }
  }

  .teams-block {
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
  }

  .tactics-system {
    position: absolute;
    top: 10px;
    left: 580px;
    z-index: 10;
    font-size: 22px;
    color: #d2f95a;

    span {
      color: white;
    }
  }

  .substitutions {
    position: absolute;
    left: 50px;
    top: 565px;
    padding-left: 16px;
    border-left: 3px solid #006e89;
    height: 318px;
    overflow: hidden;
    font-size: 22px;
    font-weight: 400;

    .substitute-line {
      display: flex;
      margin-bottom: 12px;

      & > div {
        margin-right: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .arrow {
      align-self: center;
      margin-right: 10px;
      width: 25px;
    }

    .off-player, .on-player {
      display: flex;
      align-content: center;
      line-height: 1;
      height: 26px;
      align-items: center;
    }
  }
}
