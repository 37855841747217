/*
 * T_staticmedia
 */

$className: T_staticmedia;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;

  .custom-background-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background-color: black;
    //z-index: 4;

    .custom-background {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      right: 49px;
      width: 162px;
      top: 45px;
    }

    &.sponsor-sap {
      left: 50px;
      width: 180px;
      top: 45px;
    }
  }
}
