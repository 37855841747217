/*
 * T_adlerstartingsix
 */

$className: T_adlerstartingsix;

.#{$className}.animation {

  //.headline {
  //  font-size: 186px;
  //  top: 300px;
  //  z-index: 15;
  //}
  //
  //
  //.headline2 {
  //  font-size: 186px;
  //  top: 600px;
  //  z-index: 15;
  //}

  .teams-block {
    z-index: 50;
    position: relative;

    .team-logo-container {
      //transform: rotateZ(-22deg);
      //
      //&.home {
      //  width: 400px;
      //  height: 400px;
      //}
      //
      //&.home {
      //  left: 720px;
      //  top: 635px;
      //}

      //&.away {
      //  right: 460px;
      //  top: -530px;
      //}

      &.home img, &.away img {
        //transform: rotateZ(22deg);
        position: relative;
      }
    }
  }

}

.f-1080x1920 {
  .#{$className}.animation {
    .teams-block {
      z-index: 50;
      position: relative;

      .team-logo-container {
        //transform: rotateZ(-22deg);

        //&.home {
        //  width: 400px;
        //  height: 400px;
        //}
        //
        //
        //&.home {
        //  left: 335px;
        //  top: 455px;
        //}

        //&.away {
        //  right: 460px;
        //  top: -530px;
        //}
      }
    }
  }
}

.f-1200x1500 {
  .#{$className}.animation {
    .teams-block {
      z-index: 50;
      position: relative;

      //.team-logo-container {
      //  //transform: rotateZ(-22deg);
      //
      //  &.home {
      //    width: 400px;
      //    height: 400px;
      //  }
      //
      //  &.home {
      //    left: 400px;
      //    top: 280px;
      //  }
      //
      //  //&.away {
      //  //  right: 460px;
      //  //  top: -530px;
      //  //}
      //}
    }
  }
}
