/*
 * T_adlerresults
 */

$className: T_adlerresults;

.#{$className}.animation {
  //step1 - bigger logo pre-animation
  .teams-block {
    z-index: 10;

    //.team-logo-container {
    //  //transform: rotateZ(-22deg);
    //
    //  &.home, &.away {
    //    width: 500px;
    //    height: 500px;
    //  }
    //
    //  &.home {
    //    left: 400px;
    //    top: -520px;
    //  }
    //
    //  &.away {
    //    right: 400px;
    //    top: -520px;
    //  }
    //
    //  &.home img, &.away img {
    //    //transform: rotateZ(22deg);
    //    position: relative;
    //  }
    //}
    //
    //.home {
    //  left: 400px;
    //}
    //
    //.away {
    //  right: 400px;
    //}
  }

}


.f-1200x1500 {
  .#{$className}.animation {
    .teams-block {
      //
      //.team-logo-container {
      //  &.home, &.away {
      //    width: 500px;
      //    height: 500px;
      //  }
      //
      //  &.home {
      //    left: 65px;
      //    top: -800px;
      //  }
      //
      //  &.away {
      //    right: 65px;
      //    top: -800px;
      //  }
      //}


    }
  }
}
