.teams-block {
  position: absolute;
  top: 1375px;
  width: 100%;

  .home {
    left: 120px;
    top: -320px;
  }

  .away {
    right: 120px;
    top: -320px;
    left: auto;
  }

}

.headline {
  top: 820px;
}

.custom-background-container {
  width: 1920px;
  height: auto;
}

.sponsors {

  &.sponsor-del {

    left: 70px;
    right: auto;
    margin: auto;
  }

  &.sponsor-sap {
    right: 50px;
    width: auto;
    top: auto;
    bottom: 70px;
  }
}
