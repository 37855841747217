/*
 * T_wc_seasongroupoverview
 */

$className: T_wc_seasongroupoverview;

.#{$className}.animation {


  .main-title, .sub-title, .live-matchdays-container .match {
    opacity: 0;
  }

  .headline {
    font-size: 186px;
    top: 355px;
    z-index: 15;
  }

}
