/*
 * T_wc_heatmap
 */
$className: T_wc_heatmap;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;

  .green-glow {
    color: #d2f95a;
  }

  .left-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 560px;
    height: 100%;
    background-color: #0d4d5c;
  }

  .right-block-side {
    position: absolute;
    right: 0;
    width: 1190px;
    height: 100%;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .arrows-right {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    opacity: 0.10;
    right: -20px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    img {
      height: 400px;
      margin: 0 -78px;
    }

    &.direction-left {
      transform: rotateZ(180deg)
    }
  }

  .qatar-logo {
    position: absolute;
    top: 42px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .main-headline {
    font-size: 80px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    position: absolute;
    z-index: 15;
    line-height: 1;
    text-transform: uppercase;
    top: 60px;
    left: 50px;
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .team-home, .team-away {
    width: 100px;
    position: absolute;
    top: 470px;
    filter: drop-shadow(2px 4px 6px black);
  }

  .team-away {
    right: 8px;
  }

  .team-home {
    left: 8px;
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .heatmap-block {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;

    .pitch-container {
      position: absolute;
      /* left: 12px; */
      top: 145px;
    }

    .player-heatmap {
      margin-left: 100px;
      margin-right: 100px;
      z-index: 5;
      position: relative;
      width: 891px;
      height: 577px;
      //width: 0;
      //overflow: hidden;

      path {
        transform-origin: 50% 80%;
      }
    }

    .heatmap-pitch {
      position: absolute;
      top: 24px;
      left: 126px;
      width: 850px;
      height: 515px;
      opacity: 1;
      z-index: 1;
      filter: saturate(0.3);
      //border: 1px solid #00ff80;
    }
  }


  .teams-score {
    display: flex;
    position: absolute;
    /* right: 221px; */
    top: 70px;
    left: 420px;

    .score {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      font-weight: 700;
      line-height: 1.1;

      .separator {
        margin: 0 5px;
        margin-bottom: 5px;
      }
    }


    .team-logo-container {
      //position: absolute;
      z-index: 5;
      //left: 60px;
      //top: 350px;
      width: 80px;

      &.home {
        margin-right: 30px;
      }

      &.away {
        margin-left: 30px;
      }
    }
  }


  .team-stats {
    position: absolute;
    left: 375px;
    top: 180px;
    font-size: 32px;
    width: 650px;

    .stat-label {
      text-transform: uppercase;
      font-weight: 400;
    }

    .stat-row {
      display: flex;
      align-items: center;
      border-top: 3px solid #015b77;
      padding: 10px 40px;
      justify-content: space-between;

      .stat-away, .stat-home {
        font-size: 35px;
      }

      &:last-child {
        border-bottom: 3px solid #015b77;
      }
    }
  }


  .stats-block {
    position: absolute;
    top: 830px;
    left: 722px;
    z-index: 10;
    font-size: 20px;
    display: flex;
    justify-content: space-evenly;
    width: 1100px;
    justify-content: space-evenly;
    //padding: 0 20px;

    .stat-separator {
      width: 2px;
      height: 150px;
      background-color: #30A0B2;
    }

    .stat-label {
      font-size: 34px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
    }

    .stat-value {
      font-size: 65px;
      font-weight: 800;
      line-height: 1;
      margin-top: 12px;
      text-align: center;
      color: #d2f95a;

      .sign {
        margin-left: 5px;
      }

      span {
        font-size: 45px;
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }


  .venue-container {
    text-transform: uppercase;
  }
}
