/*
 * T_tm_deal
 */

$className: T_tm_deal;

.#{$className} {
  font-family: "Source-Regular";
  -webkit-font-smoothing: antialiased;


  .comp-backgrounds-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .comp-backgrounds-container-content {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  
  .content {
    position: relative;
    height: 100%;
  }

  .block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
  }

  .fact-content-wrapper {
    display: flex;
    align-items: center;
    max-width: 50%;
  }

  .custom-background-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;

    .custom-background {
      width: 100%;
      height: 60%;
      object-fit: contain;
      position: absolute;
      top: 280px;
    }
  }

  .box {
    width: 470px;
    height: 885px;
    margin: 95px auto;
    background-color: #0d4d5c;
    padding: 45px;
  }

  .box-title {
    text-align: center;
    font-size: 48px;
    padding-top: 85px;
  }

  .box-row {
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    justify-content: space-between;
    .box-row-item-empty {
      width: 20px;
    }
    .box-row-item {
      display: flex;
      text-align: center;
      height: 90px;
      width: 160px;
      flex-direction: column;
      align-items: center;
      .box-row-item-title {
        color: #3CA8FF;
        font-size: 18px;
        font-weight: 500;
        height: 30px;
        font-family: "Source-Semibold";
      }
      .box-row-item-value {
        font-size: 36px;
        font-weight: 700;
        padding-bottom: 24px;
        font-family: "Source-Black";
      }
      .box-row-item-value-small {
        font-size: 20px;
        font-weight: 500;
        width: 100px;
      }
    }
    .box-row-item-right {
      height: 90px;
      width: 2px;
      position: relative;
      top: -20px;
    }
  }

  .tm-logo {
    display: flex;
    padding-top: 50px;
    margin: auto;
    justify-content: center;
  }

  .qatar-logo {
    width: 320px;
    //filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }

    position: relative;
    padding-bottom: 45px;
  }

  .green-glow {
    color: #d2f95a;
  }

  .main-header-container {
    display: flex;
    align-items: center;
    padding-top: 165px;
    justify-content: center;
  }

  .main-header-transfer-to {
    height: 90px;
    object-fit: contain;
    padding-right: 56px;
  }

  .main-header {
    font-size: 96px;
    font-family: "Source-Black";
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
    text-align: center;
  }

  .green-line {
    width: 30px;
    height: 4px;
    background-color: #d2f95a;

  }

  .sportworld-image-shadow {
    position: absolute;
    z-index: 100;
    height: 55px;
  }

  .information-box {
    position: absolute;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 60%;
    
  }

  .box-container {
    width: 80%;
    min-height: 100px;
    .box-background-blue {
      height: 100%;
      text-transform: uppercase;
      background-size: cover;
    }
    .box-transfer-container {
      position: absolute;
      // width: 50%;
      height: 120px;
      // left: 25%;
      left: 32%;
      top: -60px;
      display: flex;
      gap: 24px;
      padding-left: 48px;
      padding-right: 48px;
      align-items: center;

      img {
        height: 90px;
      }
    }
  }
}

.f-1200x1500 {
  .#{$className} {
    @import "./style-1200x1500";
  }
}

.f-1080x1920 {
  .#{$className} {
    @import "./style-1080x1920";
  }
}