
.comp-progress-bar {
  width: 100%;
  line-height: 0;

  //CUSTOM
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 50;

  .ant-progress {
    line-height: 0;
  }

  .ant-progress-bg {
    width: 0%;
    transition: width;
    transition-timing-function: linear;
  }
}
