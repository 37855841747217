/*
 * T_adlerlineup
 */

$className: T_adlerlineup;

.#{$className}.animation {

  //step1 - bigger logo pre-animation
  .teams-block {
    z-index: 50;
    position: relative;

    .team-logo-container {
      //transform: rotateZ(-22deg);

      //&.home {
      //  width: 400px;
      //  height: 400px;
      //}
      //
      //&.home {
      //  left: 720px;
      //  top: 615px;
      //}

      //&.away {
      //  right: 460px;
      //  top: -530px;
      //}

      &.home img, &.away img {
        //transform: rotateZ(22deg);
        position: relative;
      }
    }
  }

}


.f-1200x1500 {
  .#{$className}.animation {
    .teams-block {

      //.team-logo-container {
      //  &.home {
      //    left: 365px;
      //    top: 930px;
      //  }
      //}
    }
  }
}
