/*
 * T_adlergameday
 */
$className: T_adlergameday;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;

  .custom-background-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    //z-index: 4;

    .custom-background {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .team-vs {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    top: 1065px;
    font-size: 355px;
    color: #054888;
    line-height: 1;
    transform-origin: center center;
  }

  .teams-block {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 44;

    .home, .away {
      position: absolute;
      width: 380px;
      height: 380px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .home {
      left: 443px;
      top: 639px;
    }

    .away {
      left: 605px;
      top: 756px;
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 0;
      width: 150px;
      bottom: 65px;
      top: auto;
      right: 0;
      margin: auto;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "Predator-Sans-Cut";
    font-size: 170px;
    position: absolute;
    transform-origin: center center;

    text-align: center;
    //left: -30px;
    width: 100%;
    font-style: italic;

    div {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 100%;
      z-index: 2;

      &.main-line {
        letter-spacing: 0.015em;
        left: 2px;
        position: relative;
      }

      &.ghost-line {
        color: #cc0a1a;
        z-index: 1;
        letter-spacing: 0
      }
    }
  }

  &.side-away {
    .headline {
      div {
        &.ghost-line {
          color: #054888;
        }
      }

    }
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    font-size: 55px;
    position: absolute;
    top: 1532px;
    right: 0;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .hashtag {
      font-size: 50px;
      //margin-right: 46px;
      color: #e40520;
      margin-top: 10px;
    }

    .date-container {
    }

    .separator {
      margin: 0 15px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }
  }

  &.side-away {
    .hashtag {
      color: #054888;
    }

    .team-vs {
      color: #e40520;
    }

    .headline {


      div {
        &.ghost-line {
          color: #054888;
        }
      }

    }

  }
}

.f-1200x1500 {
  .#{$className} {
    @import "./style-1200x1500";
  }
}

.f-1080x1920 {
  .#{$className} {
    @import "./style-1080x1920";
  }
}
