/*
 * T_wc_static_facts
 */

$className: T_wc_static_facts;

.#{$className} {
  font-family: "Rubik";
  -webkit-font-smoothing: antialiased;


  .comp-backgrounds-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 1260px;
  }

  .comp-backgrounds-container-content {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .comp-backgrounds-container-top {
    width: 100%;
    height: 100%;
    position: absolute;
    top: calc(100% - 450px);
  }

  .bottom-block-content-side, .top-block-content-side {
    .fact-content-wrapper > div {
        margin-right: 30px;
    }
  }

  .comp-backgrounds-container-bottom {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: calc(100% - 450px);
  }

  .content {
    position: relative;
    height: 100%;
  }

  .left-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 1260px;
    height: 100%;
  }

  .left-block-content-side {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 1260px;
    height: 100%;
  }

  .right-block-side {
    position: absolute;
    right: 0;
    width: 1920px - 1260px;
    height: 100%;
  }

  .right-block-content-side {
    position: absolute;
    left: 0;
    width: 1920px - 1260px;
    height: 100%;
  }

  .top-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: calc(100% - 450px);
  }

  .top-block-content-side {
    position: absolute;
    top: calc(100% - 450px);
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom-block-side {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: calc(100% - 450px);
  }

  .bottom-block-content-side {
    position: absolute;
    bottom: calc(100% - 450px);
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fact-content-wrapper {
    display: flex;
    align-items: center;
    max-width: 50%;
  }

  .custom-background-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    //z-index: 4;

    .custom-background {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .box {
    width: 470px;
    height: 885px;
    margin: 95px auto;
    background-color: #0d4d5c;
    padding: 45px;
  }

  .box-row {
    width: 90%;
    height: 300px;
    display: flex;
    background-color: #0d4d5c;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    justify-content: space-between;
  }

  .qatar-logo {
    width: 320px;
    //filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }

    position: relative;
    padding-bottom: 45px;
  }

  .green-glow {
    color: #d2f95a;
  }

  .main-header {
    font-size: 40px;
    font-weight: 500;
    padding-bottom: 25px;
    position: relative;
    text-transform: uppercase;
  }

  .green-line {
    width: 30px;
    height: 4px;
    background-color: #d2f95a;

  }

  .heimspiel-country-wrapper {
    width: 40%;
  }
  .heimspiel-country-container {
    display: flex;
    margin-bottom: 36px;
    align-items: center;
    font-size: 10px;
    .heimspiel-country {
      width: 130px;
      margin-right: 24px;

      img {
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
    .heimspiel-info-country {
      font-size: 22px;
      font-weight: bold;
      margin-right: 12px;
    }

    .heimspiel-info-discipline {
      font-size: 20px;
      font-weight: 400;

    }
    .heimspiel-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .heimspiel-info-top {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .player-facts-fact {
    padding-top: 15px;
    padding-bottom: 35px;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    font-weight: 300;
  }


  .team-logo-single {
    height: 110px;
    object-fit: cover;
    margin-bottom: 40px;
  }

  .comp-match-score-widget {
    position: relative;
  }

  .player-fact-stats {
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    font-weight: 300;

    .fact {
      padding-bottom: 25px;
    }
  }

  .stat {
    display: flex;
    border-top: 2px solid rgb(88, 123, 137);
    padding-top: 20px;
    padding-bottom: 20px;

    &:last-child {
      border-bottom: 2px solid rgb(88, 123, 137);
    }

    .home {
      text-align: left;
      flex-basis: 33%;
    }

    .name {
      text-align: center;
      flex-basis: 33%;
      font-size: 20px;
      line-height: 35px;

      span {
        vertical-align: middle;
        display: inline-block;
        height: 100%;
      }
    }

    .away {
      text-align: right;
      flex-basis: 33%;
    }

    .value {
      font-size: 30px;
      color: #fff;
    }
  }

  .right-block-side {
    img.country-flag {
      padding: 0;
    }
  }

  .sportworld-image-shadow {
    position: absolute;
    z-index: 100;
    height: 55px;
  }

  &.image-side-bottom {
    .sportworld-image-shadow {
      right: 40px;
      bottom: 40px;
    }
  }

  &.image-side-top {
    .sportworld-image-shadow {
      right: 40px;
      top: 40px;
    }
  }

  &.image-side-left {
    .sportworld-image-shadow {
      left: 40px;
      top: 40px;
    }
  }

  &.image-side-right {
    .sportworld-image-shadow {
      right: 40px;
      top: 40px;
    }
  }

}
