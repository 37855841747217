.custom-background-container {
  display: none;
}

.sponsors.sponsor-del {
  left: 60px;
  width: 130px;
  top: 40px;
  display: none;
}

.sponsors.sponsor-sap {
  //width: 115px;
  text-align: center;
  /* margin: auto; */
  display: block;
  margin: 0 auto;
  bottom: auto;
  left: auto;

  right: 60px;
  width: 280px;
  top: 40px;
}

.number-of-viewers-container {
  top: 40px;

  .number-of-viewers {
    //background-color: white;
    //padding: 10px 15px;
    display: inline-block;
  }
}

.score-container {
  font-size: 350px;
  top: -50px;
}

.statistics-block {
  top: 515px;

  .stat-line-home-container, .stat-line-away-container {
    //border-radius: 20px;
    //overflow: hidden;

    .filler {
      //border-radius: 20px;
    }
  }

  .stats-mark-points {
    display: flex;
    position: absolute;
    z-index: 10;
    bottom: -20px;

    & > div {
      //border-left: 2px solid #7b0003;
      height: 10px;
      width: 32.4px;

      &:last-child {
        //border-right: 2px solid #7b0003;
      }
    }
  }

  .stat-line-home-container {
    //background-color: #00143a;

    .stats-mark-points {
      & > div {
        border-left: 2px solid #00143a;

        &:last-child {
          border-right: 2px solid #00143a;
        }
      }
    }
  }

  .stat-line-away-container {
    //background-color: #7b0003;

    //.stats-mark-points {
    //  & > div {
    //    border-left: 2px solid #7b0003;
    //
    //    &:last-child {
    //      border-right: 2px solid #7b0003;
    //    }
    //  }
    //}
  }

  .home-value, .away-value {
    border-radius: 100%;
    /* border: 6px solid #004489; */
    height: 75px;
    width: 75px;
    text-align: center;
    line-height: 80px;
    color: white;
  }

  .home-value {
    //right: 270px;
    //background: #004489;
    //box-shadow: 0 0 8px 4px #66abf2;
  }


  .away-value {
    //left: 270px;
    //background: #e40520;
    //box-shadow: 0 0 8px 4px #ec5567;
  }
}

.periods-container {
  top: 43px;

  .period-4-score .box-score {
    margin-left: 0;
  }

  .period-4-score .extra-time-info {
    margin-left: 0;
  }
}

.periods-container > div {
  width: 88px;
  height: 88px;
  font-size: 32px;
  margin-bottom: 3px;
}


.teams-block {
  top: 145px;

  .team-logo-container {
    width: 230px;
    height: 230px;
  }

  .team-logo-container.home {
    left: 380px;
  }

  .team-logo-container.away {
    right: 380px;
  }

  .home-name, .away-name {
    transform: rotateZ(
        270deg);
    top: 485px;
    font-size: 210px;
    text-align: center;
    width: 550px;
  }

  .home-name {
    left: -100px;
  }

  .away-name {
    right: -100px;
  }

  .home-name, .away-name {
    opacity: 0.4;
  }

}


.label-container {
  width: 245px;
}

