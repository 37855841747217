/*
 * T_wc_appteaser
 */

$className: T_wc_appteaser;

.T_wc_appteaser.animation {
  .teaser-text, .main-header, .winner-circle, .qr-code, .s21-mockup, .devices-magenta {
    opacity: 0;
  }

  .slide2, .slide3 {
    display: block;
  }

}
