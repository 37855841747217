/*
 * T_adlergameday
 */
$className: T_wc_opener;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;

  .green-glow {
    color: #d2f95a;
  }

  .score {
    font-size: 35px;
    color: #d2f95a;
    font-weight: 500;
    position: absolute;
    top: 590px;
    left: 379px;
    font-weight: 500;
    font-size: 50px;
    width: 200px;
    line-height: 1;

    .score-type {
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      position: absolute;
      bottom: -25px;
    }

    .score-container {
      display: flex;
      justify-content: center;
    }

    .won-by-status {
      text-transform: initial;
    }

    .separator {
      margin: 0 2px;
    }
  }

  .powered-by {
    position: absolute;
    height: 34px;
    left: 105px;
    top: 505px;
  }

  .qatar-logo {
    position: absolute;
    top: 42px;
    right: 56px;
    z-index: 10;
    z-index: 10;
    margin: auto;
    height: 50px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .main-header {
    top: 40px;
    left: 60px;
    font-size: 126px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 600;
  }

  .info-block {
    position: absolute;
    top: 360px;
    left: 100px;
    font-size: 30px;
    font-weight: 400;
  }

  .competition-positioner {
    position: absolute;
    left: 20px;
    top: 765px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
  }

  .competition-logo-container, .competition-logo-container-global {
    border-radius: 20px;
    height: 110px;
    width: 170px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .competition-logo-container-global {
    position: absolute;
    border-radius: 20px;
    height: 380px;
    width: 550px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    left: 50px;
    top: 380px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  .second-title {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: normal;
    text-align: left;
    padding-left: 26px;
    align-self: center;
    line-height: 1;
  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .competition-name {
    font-size: 80px;
    width: 1600px;
    line-height: 1;
    margin-top: 15px;
  }

  .competition-name-global {
    font-size: 100px;
    width: 1300px;
    line-height: 1;
    margin-top: 15px;
  }

  .wc-title {
    color: white;
  }

  .global-title {
    text-transform: uppercase;
    line-height: 1;
    color: #d2f95a;
    font-size: 120px;
    margin-top: -5px;
    width: 800px;
  }

  .teams-block {
    position: relative;
    //width: 500px;
    display: flex;
    //justify-content: space-between;

    //top: 0;
    //width: 100%;

    .team-vs {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0px;
      top: 0;
      font-size: 48px;
      font-weight: 700;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .versus {
        background-color: #bfcfd4;
        border: 8px solid #72949e;
        border-radius: 100%;
        //display: inline-block;
        //padding: 10px;
        color: #005175;
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        letter-spacing: -2px;
      }
    }

    .home {
      margin-right: 200px;
    }

    .home,
    .away {
      //position: absolute;
      width: 280px;
      height: 220px;
      //height: 200px;

      img {
        //width: 100%;
        //height: 100%;
        //object-fit: contain;
      }
    }

    .team-name {
      font-size: 32px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 10px;
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    font-size: 32px;
    font-weight: 600;
    position: relative;
    //padding-top: 110px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;

    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }

    span {
      font-family: "Predator-Sans-SemiLight";
    }
  }

  .venue-container {
    font-size: 26px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .extra-info {
    align-self: center;
    //margin-left: 50px;
  }

  .extra-info-line-separator {
    height: 60px;
    width: 4px;
    background-color: #3b7e89;
    align-self: center;
    margin: 0 40px;
  }

  .extra-custom-multiline {
    font-size: 50px;
    margin-top: 35px;
    line-height: 1.15;
  }
}
