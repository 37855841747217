.comp-backgrounds-wcbgcircle {
  width: inherit;
  height: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  img {
    margin: auto;
    display: block;
    max-width: 100%;
  }

  .circle-1, .circle-2, .circle-3, .circle-4 {
    will-change: transform;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .circle-1 {
    width: 1080px;
  }

  .circle-2 {
    width: 1050px;
  }

  .circle-3 {
    width: 1500px;
  }

  .circle-4 {
    width: 1840px;
  }


  &.right {
    .circle-1 {
      margin-left: 520px;
    }

    .circle-2 {
      margin-left: 520px;
    }

    .circle-3 {
      margin-left: 520px;
    }

    .circle-4 {
      margin-left: 520px;
    }
  }
}

@keyframes rotate-left {
  to {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes rotate-right {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes rotate-left {
  to {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-webkit-keyframes rotate-right {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.animation {
  .comp-backgrounds-wcbgcircle {

    .circle-1 {
      -webkit-animation: rotate-left 40s linear infinite;
      animation: rotate-left 40s linear infinite;
    }

    .circle-2 {
      -webkit-animation: rotate-right 60s linear infinite;
      animation: rotate-right 60s linear infinite;
    }

    .circle-3 {
      -webkit-animation: rotate-left 50s linear infinite;
      animation: rotate-left 50s linear infinite;
    }

    .circle-4 {
      -webkit-animation: rotate-left 80s linear infinite;
      animation: rotate-left 80s linear infinite;
    }
  }
}
