/*
 * T_staticslide
 */

$className: T_staticslide;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;

  .custom-background-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: contain;
    //z-index: 4;    
  }

  .custom-background-origin {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100%;
    top: 0;
    object-fit: cover;
  }

  .custom-background {
    width: 100%;
    height: 100%;
    position: absolute;
    
    .custom-triangle-top, .custom-triangle-bottom {
      width: 100%;
      min-height: 100%;
      height: 100%;
      opacity: 0;
      object-fit: cover;
      scale: 1.1;
    }
  }
  .custom-triangle-transition-prev, .custom-triangle-transition-next {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
    filter: blur(20px);
    object-fit: cover;
    scale: 1.1;
  }

  .custom-triangle-transition-next-full {
    width: 100%;
    min-height: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
    object-fit: cover;
    clip-path: 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    // z-index: -1;
  }

  .custom-triangle-transition-green {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
    background-color: green;
    object-fit: cover;
  }

  .custom-triangle-transition-white {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
    background-color: white;
    object-fit: cover;
  }

  .custom-triangle-transition-next-bottom {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    object-fit: cover;
    filter: blur(20px);
    // clip-path: polygon(70% -33.3%, 70% -33.3%, 17% 133%, 17% 133%);
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      right: 49px;
      width: 162px;
      top: 45px;
    }

    &.sponsor-sap {
      left: 50px;
      width: 180px;
      top: 45px;
    }
  }

  .static-slide-box-container {
    opacity: 0;
  }

  .static-slide-box-left {
    position: absolute;
    width: 540px;
    height: 500px;
    z-index: 1;
    top: 290px;
    left: 120px;
    padding: 20px;
  }

  .static-slide-box-right {
    position: absolute;
    right: 120px;
    width: 540px;
    height: 500px;
    top: 290px;
    z-index: 1;
    padding: 20px;
  }

  .template-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .static-slide-box-no {
    display: none;
  }

  .static-slide-main-box {
    background-color: #0d4d5c;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;

    .qatar-logo {
      width: 70%;
      padding-left: 40px;
      padding-top: 40px;
      img {
        width: 100%;
      }
    }

    .heimspiel-country-container {
      display: flex;
      margin-bottom: 36px;
      align-items: center;
      font-size: 10px;
      margin-top: 96px;
      margin-left: 40px;
      .heimspiel-country {
        width: 100px;
        margin-right: 24px;
  
        img {
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }
      .heimspiel-info-country {
        font-size: 22px;
        font-weight: bold;
        margin-right: 12px;
      }
  
      .heimspiel-info-discipline {
        font-size: 20px;
        font-weight: 400;
  
      }
      .heimspiel-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
  
    }

    .box-content-container {
      padding-left: 40px;
      padding-right: 40px;
    }

    .box-title-green {
      font-size: 52px;
      color: #d2f95a;
    }
    .box-desc {
      margin-top: 24px;
      font-size: 22px;
    }
  }
}
