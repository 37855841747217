.comp-player-triko {
  position: absolute;
  left: 0;
  top: 190px;
  width: 100%;

  .player {
    display: flex;
    flex-direction: column;
    align-items: center;

    .player-info {
      position: absolute;
      left: 0;
      right: 0;
      top: 390px;
    }

    .trikot {
      position: relative;
      width: 420px;

      .player-number {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 120px;
        top: 96px;
        font-weight: 400;
        line-height: 1;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
      }

      .player-position {
        font-size: 28px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
      }

      img {
        width: 330px;
        //filter: invert(1);
        margin-top: 60px;
        margin-bottom: 10px;
      }

    }

    .player-name {
      margin-top: 10px;
      line-height: 1.15;
      font-size: 50px;
      text-align: center;
      margin-bottom: 5px;
      text-transform: uppercase;

      .player-firstname {
        font-size: 45px;
      }

      .player-lastname {
        font-weight: 800;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .team-logo-container {
    left: 0;
    right: 0;

    &.home, &.away {
      //position: absolute;
      height: 140px;
      /* margin-top: 15px; */
      position: absolute;
      top: 240px;

      .team-logo.svg-logo {
        padding: 0;
      }

      img {
        //width: 100%;
        //height: 100%;
        //object-fit: contain;

      }
    }

    .team-name {
      font-size: 30px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 10px;
      white-space: nowrap;
    }
  }
}
