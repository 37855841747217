/*
 * T_adlerstartingsix
 */

$className: T_adlerstartingsix;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;

  .teams-block {
    z-index: 50;
    position: relative;

    .team-logo-container {

    }
  }

  .headline {
    font-family: "Predator-Sans-Cut";
    font-size: 165px;
    position: absolute;
    z-index: 50;


    text-align: center;
    //left: -30px;
    width: 100%;
    font-style: italic;
    transform-origin: center center;

    div {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 100%;
      z-index: 2;

      &.main-line {
        letter-spacing: 0.015em;
        left: 2px;
        position: relative;
      }

      &.ghost-line {
        color: #cc0a1a;
        z-index: 1;
        letter-spacing: 0
      }
    }
  }

  &.side-away {
    .headline {
      div {
        &.ghost-line {
          color: #054888;
        }
      }
    }
  }

  .players {
    width: 1730px;
    /* height: 100%; */
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    height: 100%;
    padding-left: 50px;
  }


  .player-group-line {
    height: 62px;
    background-color: #054888;
    font-size: 72px;
    line-height: 63px;
    z-index: 10;
    overflow: hidden;
  }

  .line-fo {
    .player-group-line {
      width: 1730px;
    }

    .player-container, .image-container {
      width: 33.33%;
    }
  }

  .line-de {
    .player-group-line {
      width: 1310px;
    }

    .players {
      width: 1310px;
    }

    .player-container, .image-container {
      width: 50%;
    }
  }

  .line-gk {
    .player-group-line {
      width: 790px;
    }

    .players {
      width: 790px;
    }

    .player-container, .image-container {
      width: 100%;
    }
  }

  &.side-away {
    .player-group-line {
      background-color: #E40521;
    }
  }

  .background-ribbons {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
  }

  .player-label {
    z-index: 12;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .sponsors {
    position: absolute;

    &.sponsor-sap {
      right: 50px;
      width: 160px;
      bottom: 45px;
    }

    &.sponsor-del {
      left: 80px;
      width: 150px;
      top: 80px;
    }
  }

  .teams-block {
    position: absolute;
    //top: 870px;
    //right: 170px;
    //transform: rotateZ(-22deg);
    z-index: 10;

    .team-logo-container {
      position: absolute;
      top: 270px;
      left: 89px;

      img {
        //transform: rotateZ(
        //    22deg);
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
      }
    }

    .team-vs {
      //transform: rotateZ(22deg);
      color: white;
    }

    .team-vs {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0px;
      top: 45px;
      font-size: 60px;
    }

  }

  .referees {
    & > div {
      width: 580px;
      text-align: center;
      word-spacing: 100vw;
      position: relative;
    }
  }

  .starting-six-container {
    position: absolute;
    top: 750px;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .side-separator {
      height: 50px;
      width: 3px;
      background-color: rgba(0, 0, 0, 0.5);
      position: relative;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 50;
      display: flex;
    }

    .players-container, .players-container-splitted {
      position: relative;
      left: 0;
      display: flex;
      /* justify-content: center; */
      flex-direction: column;
      align-items: center;
    }

    .player-container, .image-container {
      //position: absolute;
      top: 0;
      //height: 98px;
      display: flex;
      align-items: center;
      position: relative;
      //justify-content: center;


    }

    .jersey {
      //color: #E30026;
      font-family: "Predator-Sans-Cut";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 182px;
      font-size: 180px;
      text-align: center;
      z-index: 50;
      width: 100%;
      transform-origin: bottom center;
    }

    .player-info {
      line-height: 1;
      font-size: 28px;
      width: 100%;
      text-align: center;
      z-index: 30;
      position: relative;
      display: flex;
      //justify-content: space-between;
      display: flex;
      justify-content: center;

      .lastname {
        margin-bottom: 5px;
      }

      .position {
        font-family: "Predator-Sans-SemiLight";
        color: rgba(0, 0, 0, 0.5);
        font-size: 24px;
      }
    }

    .player-image-container {
      transform-style: preserve-3d;
      z-index: 5;
      width: 355px;
      height: 200px;
      position: absolute;
      overflow: hidden;
      left: 0;
      right: 0;
      margin: auto;
      transform-origin: bottom center;


      .player-image {

        img {
          width: 100%;
          height: 100%;
          //object-fit: cover;
          //object-position: top;
        }
      }
    }


    .ribbon-container {
      height: 100px;
      width: 1700px;
      padding-right: 100px;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      display: none;

      .ribbon-block {
        width: 100%;
        height: 100%;
      }

      .ribbon {
        width: 100%;
        height: 100%;
        background-color: #E30026;
      }

      .ribbon-circle {
        border-radius: 100%;
        background-color: #E30026;
        width: 200px;
        height: 200px;
        position: absolute;
        bottom: -10px;
        right: 0;

      }
    }
  }

  &.side-home {
    .team-vs {
      color: #004187;
    }
  }

  &.other-stats {
    .sponsor-del {
      opacity: 0;
    }
  }

  &.side-away {
    .team-vs {
      color: #E30026;
    }


  }
}

.f-1200x1500 {
  .#{$className} {
    @import "./style-1200x1500";
  }
}

.f-1920x1080 {
  .#{$className} {
    @import "./style-1920x1080";
  }
}

.f-1080x1920 {
  .#{$className} {
    @import "./style-1080x1920";
  }
}
