.comp-timeline-live-indicator {
  position: absolute;
  z-index: 500;
  left: 0;
  top: 0;

  .blink-me-slower {
    animation: blink 1s linear infinite;
  }

  @keyframes blink {
    from {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    to {
      visibility: visible;
    }
  }

  .live-indicator {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #FF1824;
    display: flex;
    text-transform: uppercase;

    align-items: center;

    & > div {
    }


    .status-text {
      margin-right: 5px;
      font-size: 18px;
      line-height: 1;
      margin-top: 2px;
    }
  }

  .live-circle {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: red;
  }
}
