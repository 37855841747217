/*
 * T_wc_appteaser
 */
$className: T_wc_appteaser;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .green-glow {
    color: #d2f95a;
  }

  .teaser-text {
    font-size: 74px;
    line-height: 1.1;
  }

  .text-1 {
    position: absolute;
    left: 100px;
    top: 420px;
  }

  .text-2 {
    position: absolute;
    left: 100px;
    top: 665px;
  }

  .slide2 {
    .text-1 {
      top: 440px;
    }

    .text-2 {
      top: 760px;
    }
  }

  .slide3 {
    .text-1 {
      top: 430px;
    }

    .text-2 {
      top: 760px;
    }
  }

  .powered-by {
    position: absolute;
    height: 166px;
    left: 100px;
    top: 120px;
  }

  .teaser_bg {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .teaser_bg_layer2 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    z-index: -1;
  }

  .winner-circle {
    position: absolute;
    right: 0;
    top: 235px;
    width: 620px;
    filter: drop-shadow(2px 4px 6px black);

    img {
      max-width: 100%;
    }
  }

  .devices-magenta {
    position: absolute;
    right: 30px;
    top: 250px;
    width: 1011px;
    filter: drop-shadow(2px 4px 6px black);

    img {
      max-width: 100%;
    }
  }

  .qr-code {
    position: absolute;
    right: 40px;
    bottom: 40px;
    width: 674px;
    filter: drop-shadow(2px 4px 6px black);
    z-index: 5;

    img {
      max-width: 100%;
    }
  }

  .s21-mockup {
    position: absolute;
    right: 190px;
    bottom: 0;
    width: 891px;
    filter: drop-shadow(2px 4px 6px black);
    z-index: 4;

    img {
      max-width: 100%;
    }
  }

  .main-header {
    top: 60px;
    left: 100px;
    font-size: 160px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
  }

  .wc-title {
    color: white;
  }

  .global-title {
    text-transform: uppercase;
    line-height: 1;
    color: #d2f95a;
    font-size: 120px;
    margin-top: 60px;
    width: 650px;
  }

  .teams-block {
    position: relative;
    //width: 500px;
    display: flex;
    //justify-content: space-between;

    //top: 0;
    //width: 100%;

    .team-vs {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0px;
      top: 36px;
      font-size: 48px;
      font-weight: 700;
      line-height: 1;
      display: flex;
      justify-content: center;

      .versus {
        background-color: #bfcfd4;
        border: 12px solid #72949e;
        border-radius: 100%;
        //display: inline-block;
        //padding: 10px;
        color: #005175;
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        letter-spacing: -2px;
      }
    }

    .home {
      margin-right: 200px;
    }

    .home,
    .away {
      //position: absolute;
      width: 260px;
      //height: 200px;

      img {
        //width: 100%;
        //height: 100%;
        //object-fit: contain;
      }
    }

    .team-name {
      font-size: 32px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 10px;
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;

    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }

    span {
      font-family: "Predator-Sans-SemiLight";
    }
  }

  .slide2,
  .slide3 {
    display: none;
  }

  .venue-container {
    text-transform: uppercase;
  }
}
