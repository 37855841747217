/*
 * T_adlergoal
 */
$className: T_adlergoal;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;
  //color: #0F1F3A;
  color: #fff;


  .videobg-1 {
    position: relative;
    z-index: 1;
    //width: 100%;
    //height: 100%;
  }

  .videobg-2 {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .player {
    position: absolute;
    /* left: 0; */
    top: 180px;
    width: 110%;
    left: -5%;
    -o-object-fit: cover;
    object-fit: cover;
    /* -webkit-mask: linear-gradient(360deg, rgba(0, 0, 0, 0) 8%, black 20%); */
    -o-object-position: top;
    object-position: top;
    height: 1300px;
    z-index: 4;
  }

  .player-shadow-container {
    position: absolute;
    width: 110%;
    left: 80px;
    z-index: 2;
    top: 930px;
    mix-blend-mode: overlay;

    img {
      width: 110%;
      object-fit: cover;
      object-position: top;
      height: 1100px;
      z-index: 5;
    }

  }

  .teams-block {
    position: absolute;
    top: 1360px;
    width: 100%;
    z-index: 10;

    div {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
      top: 0;
      font-size: 210px;
    }

    .home, .away {
      position: absolute;

      width: 250px;
      height: 250px;
      object-fit: contain;
      top: 50px;
    }

    .home {
      right: 760px;
    }

    .away {
      left: 760px;
    }
  }

  .sponsors {
    position: absolute;
    top: 40px;


    &.sponsor-del {
      left: 0;
      width: 150px;
      top: 1730px;
      right: 0;
      margin: auto;
      z-index: 10;
      bottom: 0;
    }

    &.sponsor-sap {
      right: 40px;
      width: 170px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 320px;
    position: absolute;
    top: 25px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .goal-description-box {
    display: flex;
    position: absolute;
    top: 1665px;
    left: 0;
    width: 100%;
    /* text-align: center; */
    justify-content: center;
    font-size: 42px;
    line-height: 1;
    z-index: 15;


    .period-separator {
      margin: 0 20px;
    }
  }

  .player-name {
    position: absolute;
    bottom: 540px;
    left: 0;
    text-align: center;
    width: 100%;
    font-size: 82px;
    line-height: 1.10;
    color: white;
    z-index: 50;
    //transform: rotateZ(-90deg);

    .surname {
      //font-family: "EdoSZ";
      font-size: 120px;
      //color: #0F1F3A;
    }

    .name {
      font-family: "Predator-Sans-SemiLight";
      font-size: 58px;
    }

    //.side-away {
    //  .surname {
    //    color: #e50018;
    //  }
    //}
  }

}

//.f-1080x1920 {
//  .#{$className} {
//    @import "./style-1080x1920";
//  }
//}


