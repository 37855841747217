/*
 * T_wc_livematchday
 */

$className: T_wc_livematchday;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .green-glow {
    color: #d2f95a;
  }

  .main-title {
    font-size: 70px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    position: absolute;
    left: 0;
    text-align: center;
    right: 0;
    top: 36px;

  }

  .wc-content {
    position: absolute;
    top: 0;
    z-index: 10;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
  }

  .qatar-logo {
    position: absolute;
    top: 52px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .headline {
    font-size: 90px;
    top: 50px;
    z-index: 15;
    color: #fff;
    position: relative;
    text-align: center;

    .stroke-shadow, .stroke-shadow-2 {
      top: -9%;
      right: 0;
      left: 0;
      bottom: 0;
      font-size: 110px;
      position: absolute;
      color: white;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
    }
  }

  .sponsors {
    position: absolute;

    &.sponsor-sap {
      right: 65px;
      width: 260px;
      top: 90px;
    }
  }

  .live-matchdays-positioner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 688px;
    overflow: hidden;
    margin-top: 142px;
  }

  .live-matchdays-container {
    width: 1350px;
    margin: auto;
    margin-top: 0;
    color: #fff;
    position: relative;


    img.country-flag {
      padding: 5px;
    }

    .match {
      line-height: 1;
      display: flex;
      justify-content: center;
      height: 68px;
      align-items: center;
      font-size: 28px;
      position: relative;

      border-bottom: 4px solid #3b7e89;

      &.adler-match {
        color: #ff2222;
      }

      &.status-live {
        .score-home, .score-away, .away-name, .home-name {
          font-weight: 600;
        }

        .match-status {
          color: #ff2222;
          text-transform: uppercase;
        }
      }

      .score-container {
        display: flex;
        justify-content: center;
        position: relative;
        width: 170px;
        font-size: 46px;
        line-height: 1;
        margin-top: -1px;
        font-weight: 100;
      }

      &:first-child {
        border-top: 4px solid #3b7e89;
      }
    }


    .home-logo, .away-logo {
      height: 100%;


      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .home-logo {
      margin-left: 45px;
    }

    .away-logo {
      margin-right: 45px;
    }

    .team-logo-container {
      &.home, &.away {
        width: 60px;
        height: 55px;

        img {
          //width: 100%;
          //height: 100%;
          //object-fit: contain;
        }
      }

      &.home {
        margin-left: 10px;
      }

      &.away {
        margin-right: 10px;
      }
    }


    .home-name {
      width: 380px;
      text-align: right;
      white-space: nowrap;
      font-weight: 300;
    }

    .away-name {
      width: 380px;
      text-align: left;
      white-space: nowrap;
      font-weight: 300;
    }

    .match-status {
      position: absolute;
      bottom: 6px;
      width: 100%;
      text-align: center;
      font-size: 19px;
      right: -760px;
    }

    .match-datetime {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 20px;
      left: -510px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      font-weight: 600;

      &.left-side {
        right: auto;
        left: -590px;
      }

      &.match-datetime--align {
        align-items: flex-start;
      }
    }

    .time {
      font-weight: normal;
    }

    .group-info {
      position: absolute;
      top: 0;
      width: 100%;
      text-align: left;
      font-size: 20px;
      left: -120px;
      color: #d2f95a;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .attendance-info {
      position: absolute;
      top: 0px;
      width: 100%;
      text-align: center;
      font-size: 19px;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      width: 100%;

      &.right-side {
        right: -590px;
      }

      span {
        margin-right: 10px;
      }
    }
  }

  .extra-time-info {
    font-size: 26px;
    color: white;
    position: absolute;
    left: 146px;
    top: 20px;
    color: #ff0000;
  }


  .blink-me {
    animation: blink 1s linear infinite;
  }

  @keyframes blink {
    from {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    to {
      visibility: visible;
    }
  }

  .score-playing {
    color: #d2f95a;
    font-weight: 400;
  }

  .score-data-container {
    display: flex;
  }

  .score-separator--margin {
    margin: 0 8px;
  }

  .competition-positioner {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
  }

  .competition-logo-container {
    border-radius: 20px;
    height: 110px;
    width: 170px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .second-title {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: normal;
    text-align: left;
    padding-left: 26px;
    align-self: center;
    line-height: 1;
  }

  .wc-bg-content--opasity {
    opacity: 1 !important;
  }

  .comp-live-status-badge {
    top: 9px;
    left: 88px;
  }
}
