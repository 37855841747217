.comp-player {
  position: absolute;
  left: 100px;
  top: 200px;
  width: 400px;
  //height: 600px;
  display: flex;
  align-items: center;

  img {
    border-style: none;
    object-fit: contain;
    width: 100%;
    height: auto;
  }
}
