/*
 * T_adlertopscorer
 */

$className: T_adlertopscorer;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;

  .team-logo-container {
    position: absolute;
    width: 130px;
    height: 130px;

    //transform: rotateZ(
    //    -22deg);
    z-index: 44;

    &.home {
      top: 144px;
      left: 160px;
    }


    &.away {
      bottom: 188px;
      left: 165px;
    }

    img {
      //transform: rotateZ(
      //    22deg);
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: relative;
    }
  }

  .names {
    display: flex;
    gap: 8px;
  }

  .headline {
    font-family: "Predator-Sans-Cut";
    font-size: 125px;
    position: absolute;
    z-index: 50;
    top: 45px;


    text-align: center;
    //left: -30px;
    width: 100%;
    font-style: italic;
    transform-origin: center center;

    div {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 100%;
      z-index: 2;

      &.main-line {
        letter-spacing: 0.015em;
        left: 2px;
        position: relative;
      }

      &.ghost-line {
        color: #cc0a1a;
        z-index: 1;
        letter-spacing: 0
      }
    }
  }

  &.side-away {
    .headline {
      div {
        &.ghost-line {
          color: #054888;
        }
      }
    }
  }

  .sponsors {
    position: absolute;

    &.sponsor-sap {
      right: 830px;
      width: 120px;
      top: 215px;
    }
  }

  .top-scorers-home, .top-scorers-away {
    //transform: rotateZ(
    //    -22deg);
    line-height: 1;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;

    .scorer-stats {
      position: absolute;
      top: 102px;
      font-size: 40px;

      & > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        font-family: "Predator-Sans-Cut";
      }

      & > div:nth-child(3) {
        .separator {
          display: none;
        }
      }

      .value {
        width: 82px;
        //height: 58px;
        //line-height: 58px;
        font-size: 42px;
        //color: #e5001a;
        z-index: 6;
        align-items: center;
        align-content: center;
        display: flex;
        justify-content: center;
        height: 80px;
        padding-top: 3px;
      }

      .label {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 22px;
        z-index: 6;

      }
    }

    & > div {
      position: absolute;
    }

    .scorer {
      width: 400px;
      height: 460px;
      //background: rgba(0, 0, 0, 0.3);
      position: absolute;
    }

    .scorer:nth-child(1) {
      top: 150px;
      left: 280px;
    }

    .scorer:nth-child(2) {
      top: 565px;
      left: 40px;
    }

    .scorer:nth-child(3) {
      top: 565px;
      left: 500px;
    }

    .firstname, .lastname {
      //width: 300px;
      text-align: left;
      //left: -110px;
      //top: 20px;
      position: relative;
      //margin-top: 20px;
    }

    .lastname {
      //font-family: "EdoSZ";
      //top: 23px;
      //color: #e5001a;
      //font-size: 36px;
      margin-top: 0;
    }

    .separator {
      height: 4px;
      background-color: #e4051f;
      width: 80px;
      margin: 14px 0px;
      border-radius: 20px;
    }


  }

  .top-scorers-away {
    top: auto;
    right: -10px;
    left: auto;
    //.points {
    //  opacity: 0;
    //}

    .scorer-stats {
      .value {
        color: white;
      }
    }


    .separator {
      background-color: #173d80;
    }

    .box-bg {
      .triangle1 {
        background: #E40521;
      }
    }

    .player-info-block {
      .full-name {
        background: #E40521;
      }
    }
  }

  .box-bg {
    display: flex;
    position: absolute;
    z-index: 4;

    /* 2*sin(120/2)*h = w
       h = w / (sin(60)*2)
       h = w * 0.57736
    */
    .triangle1:before {
      content: "";
      display: block;
      padding-top: 60%;
    }

    .triangle1 {
      background: #014389;
      clip-path: polygon(0 50%, 50% 100%, 100% 50%, 50% 0);
      width: 120px;
      display: inline-block;
      margin: 5px;
    }
  }

  //&.side-away {
  //  .triangle1 {
  //    background:#e60026;
  //  }
  //}


  .player-image {
    width: 325px;
    height: 410px;
    /* overflow: hidden; */
    position: absolute;
    right: -15px;
    //left: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom;
      position: relative;

    }
  }

  .player-info-block {
    font-size: 30px;
    /* left: 25px; */
    //padding-left: 25px;
    position: relative;
    font-family: 'Predator-Sans-Cut';
    height: 100%;


    .full-name {
      background-color: #054888;
      display: flex;
      justify-content: center;
      column-gap: 8px;
      padding: 6px;
      width: 100%;
      bottom: 0;
      position: absolute;
    }
  }


  &.side-home {

  }

  &.side-away {

  }


}
