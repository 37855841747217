.comp-live-status-badge {

  &.live-status {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    justify-content: center;
    font-weight: 600;

    .white-bg, .red-bg {
      padding: 2px 5px;
      background-color: #fc0031;
      color: #ffffff;
      display: flex;
      align-items: center;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;

      > div {
        line-height: 0;
      }
    }

    .white-bg {
      position: absolute;
      z-index: 4;
      background-color: #ffffff;
      color: #fc0031;

      .circle {
        background-color: #fc0031;
      }
    }

    .red-bg {
      z-index: 3;
      background-color: #fc0031;
      color: #ffffff;

      .circle {
        background-color: #ffffff;
      }
    }
  }

  .circle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    align-items: center;
    margin: 0 4px;
    top: -1px;
    position: relative;
  }

  .animation-container {
    position: relative;
    width: 80px;
    height: 27px;
    overflow: hidden;
  }

  .white-bg-positioner, .red-bg-positioner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }

  .white-bg {
    animation: revealHide 4s cubic-bezier(0.2, 0.6, 0.2, 1) forwards infinite;
  }

  .blink-me {
    animation: blink-animation 1s steps(5, start) infinite;
  }

  .circle {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
  }

  @keyframes revealHide {
    0%, 100% {
      clip-path: inset(0 100% 0 0);
    }
    40%, 50% {
      clip-path: inset(0 0 0 0);
    }
    100% {
      clip-path: inset(0 0 0 100%);
    }
  }

  @keyframes blink-animation {
    50% {
      opacity: 0;
    }
  }


  &.alarm-live-status-badge {
    position: relative;
    top: 0;
    font-size: 30px;
    margin-right: 150px;

    .animation-container {
      width: 110px;
      height: 50px;
    }
  }
}

