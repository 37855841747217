/*
 * T_adlermatchrankwise
 */
$className: T_adlermatchrankwise;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;

  .qatar-logo {
    position: absolute;
    right: 112px;
    top: 235px;
  }

  .player-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 100%;
    overflow: hidden;
    text-align: center;
  }

  .green-glow {
    color: #d2f95a;
  }

  .qatar-logo {
    position: absolute;
    top: -85px;
    left: 0;
    right: 0;
    z-index: 10;
    width: 140px;
    margin: auto;
    filter: drop-shadow(2px 4px 6px black);
  }

  .main-header {
    top: 100px;
    left: 100px;
    font-size: 160px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .coach-info {
    position: absolute;
    top: 650px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .team-logo-container {
    position: absolute;
    z-index: 5;
    left: 60px;
    top: 350px;
    width: 340px;

  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 100px;
      width: 150px;
      bottom: 60px;
      top: auto;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .fastestScater {

    .headline, .player-info {
      color: #0F1F3A;
    }

    .group-table {
      color: #0F1F3A;
    }
  }

  .player-image {
    /* width: 680px; */
    position: relative;
    /* top: 0; */
    height: 100%;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }


  .player {
    height: 850px;
    position: absolute;
    bottom: 0;
  }

  .player-0 {
    right: 290px;


    .player-image {
      transform: scale(1.15);
      z-index: 12;
    }
  }

  .player-1 {
    right: 70px;
    bottom: -15px;

    .player-image {

      z-index: 9;
    }
  }

  .player-2 {
    right: -150px;
    bottom: -60px;

    .player-image {
      z-index: 7;
    }
  }

  .player-info {
    font-family: "Predator-Sans-Rounded";
    position: absolute;
    font-size: 48px;
    left: 40px;
    top: 580px;
    width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;


    &.info-1 {
      left: 381px;
    }

    &.info-2 {
      left: 720px;
    }

    .stat-value {
      font-size: 80px;
      margin-top: 12px;
    }


    .stat-type {
      font-size: 42px;
      margin-top: 12px;
    }
  }

  .headline {
    font-family: "Predator-Sans-Cut";
    font-size: 125px;
    position: absolute;
    z-index: 50;
    line-height: 1.05;
    text-align: left;
    font-style: italic;
    transform-origin: left center;
    left: 100px;
    top: 220px;

    div {
      position: absolute;
      left: 0;
      top: 0;
      //width: 100%;
      z-index: 2;
      white-space: nowrap;
      width: 100%;


      &.main-line {
        letter-spacing: 0.010em;
        position: relative;

        div {
          position: relative;
        }
      }

      &.ghost-line {
        color: #cc0a1a;
        z-index: 1;
        letter-spacing: 0
      }
    }
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .venue-container {
    text-transform: uppercase;
  }

  .vs-team-info {
    font-size: 26px;
  }

  .wc-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
  }

  .lineup {
    position: absolute;
    left: 580px;
    top: 130px;
    z-index: 5;
    text-transform: uppercase;

    .lineup-content {
      font-size: 30px;
      line-height: 1.4;
    }

    .player {
      display: flex;

      .shirt-number {
        width: 50px;
        text-align: right;
        margin-right: 15px;
        font-weight: 300;
      }

      .player-name {
        font-weight: 600;
      }
    }

    .wc-headline {
      font-size: 70px;
      font-weight: 700;
    }
  }

  .lineup-reserve {
    left: 1080px;
    font-size: 40px;

    .lineup-content {
      font-size: 26px;
      //line-height: 1.4;
    }

    .player {
      .player-name {
        font-weight: 400;
      }
    }


    .wc-headline {
      margin-top: 25px;
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}

.f-1200x1500 {
  .#{$className} {
    @import "./style-1200x1500";
  }
}
