/*
 * T_wc_country_medals
 */
$className: T_wc_country_medals;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;


  .olympic-matchday {
    font-size: 45px;
    font-weight: 400;
    text-align: left;
    position: relative;
    top: 50px;
    text-transform: uppercase;
    left: 50px;
  }

  .green-glow {
    color: #d2f95a;
  }

  .competition-logo-container {
    border-radius: 20px;
    width: 300px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px 20px; */
    text-align: center;
    margin: auto;
    position: absolute;
    left: 0;
    width: 300px;
    margin: auto;
    right: 0;
    top: 240px;

    img {
      //width: 100%;
      //height: 100%;
      object-fit: contain;
    }
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .qatar-logo {
    position: absolute;
    top: 65px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }
  }

  .left-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 560px;
    height: 100%;
    background-color: #0d4d5c;
  }

  .right-block-side {
    position: absolute;
    right: 0;
    width: 1190px;
    height: 100%;
    z-index: 20;
  }

  .attacking-zones {
    width: 100%;
    background-repeat: no-repeat;
    height: 735px;
    position: absolute;
    background-size: 100% 100%;
    bottom: 0;
    overflow: hidden;

    .arrows {
      position: absolute;
      display: flex;
      justify-content: space-between;
      bottom: 0;
      width: 100%;
      height: 77%;
    }
  }

  .teams-block {
    display: flex;
    position: absolute;
    top: 290px;
    left: 0;
    right: 0;
    /* width: 100%; */

    .home,
    .away {
      //position: absolute;
      width: 100%;
      height: 280px;
      //height: 200px;

      img {
        //width: 100%;
        //height: 100%;
        //object-fit: contain;
      }
    }

    .team-logo-container {}

    .team-name {
      font-size: 30px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 25px;
      white-space: nowrap;
    }
  }

  .main-header {
    font-size: 70px;
    font-weight: 500;
    display: flex;
    justify-content: left;
    line-height: 1;
    margin-bottom: 50px;
    z-index: 100;
    position: relative;
    top: 50px;
    text-transform: uppercase;
    padding: 0 50px;
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .group-table {
    position: absolute;
    left: 0;
    top: 200px;
    font-size: 28px;
    width: 100%;
    padding: 0 30px;

    .group-label {
      font-size: 80px;
      //text-transform: uppercase;
      font-weight: 700;
      line-height: 1;
    }

    .group-content {
      font-size: 36px;
      text-transform: initial;
      font-weight: 400;
    }

    .table-head {
      display: flex;
      justify-content: flex-end;
      padding: 10px 0;
      font-size: 24px;
      font-weight: 400;
      //margin-right: 34px;
      text-align: center;
    }

    .table-row {
      display: flex;
      align-items: center;
      border-top: 4px solid #367f8b;
      padding: 5px 0;

      &:nth-of-type(2) {
        border-top: 4px solid transparent;
      }

      &:last-child {
        border-bottom: 4px solid #367f8b;
      }
    }

    .table-head, .table-row {
      display: flex;
    }

    .col {
      flex: 1;
      text-align: center;
    }

    .col-pos {
      flex: 0;
      min-width: 60px;
    }

    .col-logo {
      margin-right: 15px;
      display: flex;
      align-items: center;


      .team-logo {
        height: 80px;
        width: 100px;
      }

      .team-logo.svg-logo {
        padding: 0;
      }
    }

    .col-team {
      margin-right: 20px;
      min-width: 600px;
      display: flex;
      line-height: 1.1;
      align-items: center;

      img.country-flag {
        height: 44px;
        padding: 2px;
      }
    }

    .col-points {
      //margin-left: 5px;
      width: 100px;
    }

    .col-diff {
      //margin-left: 5px;
      min-width: 150px;
    }

    .col-pkt {
      color: #d2f95a;
      //margin-left: 5px;
      width: 120px;
    }

  }

  .col-medal-icon {
    height: 50px;
  }

}
