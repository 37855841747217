.player-0 {
  z-index: 5;
}

.player-1 {
  z-index: 4;
}

.player-2 {
  z-index: 3;
}

.player-info {
  z-index: 15;
}

.static-bg, .video-bg {
  width: 100%;
  height: 100%;
}

.video-bg {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
}

.sponsor-del {
  left: 0;
  width: 150px;
  bottom: 60px;
  top: auto;
  z-index: 30;
  right: 0;
  margin: auto;
}

.player-info {
  //left: 0;
  top: 1050px;
  width: 390px;

  &.info-2 {
    left: 20px;
  }

  &.info-0 {
    left: 410px;
  }

  &.info-1 {
    left: 790px;
  }
}


.player-0 {
  bottom: 550px;
  right: 270px;
}

.player-1, .player-2 {
  bottom: 510px;
}


.player-1 {
  right: -50px;
}

.player-2 {
  right: auto;
  left: -50px;
}

.main-line, .ghost-line {
  text-align: center;
}

.headline {
  top: 680px;
  left: 0;
  transform-origin: center;
  justify-content: center;
  width: 100%;
}

.fastestScater {

  .headline, .player-info {
    color: #fff;
  }

  .group-table {
    color: #fff;
  }
}
