.team-vs {
  width: 100%;
  text-align: center;
  left: 0;
  top: 1450px;
  font-size: 300px;
  color: #054888;
  line-height: 1;
}

.headline {
  line-height: 1.05;
  top: 150px;
  left: -15px;
}

.teams-block {
  position: relative;

  .team-vs {
    display: block;
  }

  .team-logo-container {
    width: 240px;
    height: 240px;
    z-index: 5;

    top: 1480px;

    &.home {
      left: 270px;
    }

    &.away {
      right: 270px;
      left: auto;
    }
  }
}


//&.side-home {

//}
//
//&.side-away {

//}

.starting-six-container {
  top: 440px;
}


.player-image-container {
  width: 100%;
}

.players-container-splitted {

  .player-image-container {
    //height: 340px !important;

  }

  .jersey {
    text-align: center;
    right: 0;
    line-height: 1;
    white-space: nowrap;
    /* font-weight: normal; */
    z-index: 5;
    /* bottom: 20px; */
    position: relative;
    top: auto;
    /* z-index: -13333; */
    bottom: 112px;
  }

  .player-info {
    position: absolute;
    text-align: center;
    top: 11px;
    font-size: 40px;
  }

  .firstname, .lastname {
    text-shadow: 1px 1px #464646;
    position: relative;
    z-index: 5;
  }

  .lastname {
    color: #fff;
  }

  .firstname {
    //color: #64020d;
    margin-right: 8px;
  }

  .player-image-container {
    bottom: 62px;
  }


  &.line-de {
    margin-top: 200px;
  }


  &.line-fo {

    .jersey {
      //opacity: 0.8;
    }
  }

  &.line-gk {
    margin-top: 200px;
    //top: 174px;
    //left: 1515px;

    .firstname, .lastname {
      //color: #003a8e;
      //text-shadow: 1px 1px #dbdbdb;
    }

    //.player-bottom-gradient {
    //  background: linear-gradient(0deg, #0f1d36 2%, rgba(138, 128, 132, 0) 80%);
    //}
    //.player-container:nth-child(1) {
    //  .player-info {
    //    top: 120px;
    //    left: -110px;
    //  }
    //}
  }

  .player-image-container {
    //height: 320px;
  }
}

&.other-stats {
  .players-container-splitted {

    //.player-bottom-gradient {
    //  display: none;
    //}
    //
    //.jersey {
    //  font-size: 140px;
    //  top: -66px;
    //}
    //
    //.player-image-container {
    //  display: none;
    //}

  }
}

&.side-away {


}

.sponsors {
  &.sponsor-sap {
    display: none;
  }

  //&.sponsor-del {
  //  display: none;
  //}
}


.line-fo {
  .player-group-line, .players {
    width: 975px;
  }

  .player-group-line {
    div {
      display: none;
    }
  }

  .players {
    padding-left: 0;
  }
}

.line-de {
  .player-group-line, .players {
    width: 680px;
  }

  .player-group-line {
    div {
      display: none;
    }
  }

  .players {
    padding-left: 0;
  }
}

.line-gk {
  .player-group-line, .players {
    width: 345px;
  }

  .player-group-line {
    div {
      display: none;
    }
  }

  .players {
    padding-left: 0;
  }
}

.starting-six-container {
  top: 810px;
}

.sponsor-del {
  left: 0;
  width: 150px;
  top: 1790px;
  right: 0;
  margin: auto;
}

&.side-away {
  .team-vs {
    color: #e40520;
  }
}
