.app-opta {
  background: transparent;

  .timeline-container {
    width: inherit;
    height: inherit;
  }

  .template-container {
    position: absolute;
    left: 0;
    top: 0;
    //background-color: red;
  }

  .outro-optional-video {
    position: absolute;
    z-index: 5000;
    opacity: 0;
    pointer-events: none;
  }

  .wc-bg-content {
    position: absolute;
    background: linear-gradient(90deg, #004d5c 0%, #0d2439 100%);
    width: 100%;
    height: 100%;
    z-index: 0;
    left: 0;
    top: 0;
    opacity: 0.95;
  }

  .wc-content-v2 {
    position: absolute;
    background: linear-gradient(90deg, #0d5965 0%, #0d2439 100%);
    width: 1750px;
    height: 900px;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    opacity: 1;
  }

  .green-glow {
    color: #d2f95a;
  }

  .timeline-container {
    background: linear-gradient(90deg, #004d5c 0%, #00112c 100%);
  }

  .global-error-boundary {
    background: linear-gradient(90deg, #004d5c 0%, #00112c 100%);

    .loader-container.inline {
      justify-content: flex-start;
    }

    .anticon-sync {
      color: black;
    }

  }

  .template-container {
    .team-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;

      &.svg-logo {
        padding: 10px;
      }
    }
  }

  .root-container {
    background: linear-gradient(90deg, #004d5c 0%, #00112c 100%);
  }

  .blink-me {
    animation: blink 1s linear infinite;
  }

  .animation {
    .comp-match-score-widget {
      opacity: 0;
    }
  }

  .competition-logo {
    width: 35px;
    height: 35px;
    margin-right: 12px;
  }

  .logo-generic {
    padding: 7px;
  }

  img.country-flag {
      padding: 25px;
  }

}
