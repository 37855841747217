/*
 * T_adlermatchrankwise
 */

$className: T_adlermatchrankwise;

.#{$className}.animation {
  .player-0 {

    .player-image {
      transform: none;
    }
  }
}
