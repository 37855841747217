.app-opta {
  color: black;

  .comp-MatchDayPopup {
    .teams-block {
      //[data-id]:not([data-id="2"]) {
      //  display: none;
      //}

      //.team-logo {
      //  display: none;
      //}

      .team-logo {
        height: auto;
        width: 60px;
        height: 60px;
        object-fit: contain;
        object-position: center;
        text-align: center;
        margin: auto;
      }
    }
  }
}
