/*
 * T_adlermatchstatswise
 */
$className: T_adlermatchstatswise;

.#{$className} {
  font-family: "Predator-Sans-SemiLight";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;

  .green-glow {
    color: #d2f95a;
  }

  .wc-content {
    position: absolute;
    left: 50px;
    right: 50px;
    bottom: 50px;
    top: 50px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.90);
    border-radius: 20px;
  }

  .wise-content {
    top: 100px;
    position: absolute;
    overflow: hidden;
    height: 880px;
    width: 100%;
  }

  .qatar-logo {
    position: absolute;
    top: -85px;
    left: 0;
    right: 0;
    z-index: 10;
    width: 140px;
    margin: auto;
    filter: drop-shadow(2px 4px 6px black);

    img {
      width: 100%;
    }
  }

  .main-header {
    top: 100px;
    left: 100px;
    font-size: 160px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;

  }

  .info-block {
    position: absolute;
    top: 580px;
    left: 100px;
    font-size: 30px;
    font-weight: 400;
  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 1px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .teams-block {
    display: flex;
    position: absolute;
    top: 400px;
    left: 0;
    right: 0;
    /* width: 100%; */
    justify-content: space-between;

    .home, .away {
      //position: absolute;
      width: 365px;
      //height: 200px;

      img {
        //width: 100%;
        //height: 100%;
        //object-fit: contain;
      }
    }

    .team-name {
      font-size: 30px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 10px;
      white-space: nowrap;
    }
  }

  .score {
    font-size: 100px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-bottom: 20px;

    .score-home {
      width: 150px;
      text-align: right;
    }

    .score-away {
      width: 150px;
      text-align: left;
    }

    .separator {
      margin: 0 10px;
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .slide-1, .slide-2, .slide-3 {
    position: absolute;
    width: 100%;
  }

  .headline-wise {
    font-family: "EdoSZ";
    font-size: 60px;
    position: absolute;
    top: 0px;
    line-height: 1;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .team-stats {
    left: 0;
    top: 30px;
    font-size: 32px;
    width: 50%;
    right: 0;
    position: absolute;
    margin: auto;
    width: 1200px;

    &.stats-1 {
      padding-right: 25px;
    }

    &.stats-2 {
      right: 0;
      left: auto;
      padding-left: 25px;
    }

    .stat-label {
      text-transform: uppercase;
      font-weight: 400;
    }

    .stat-row {
      display: flex;
      align-items: center;
      border-top: 1px solid #d2f95a;
      padding: 10px 40px;
      justify-content: space-between;

      .stat-away, .stat-home {
        font-size: 35px;
      }

      &:last-child {
        border-bottom: 1px solid #d2f95a;
      }
    }
  }


  .venue-container {
    text-transform: uppercase;
  }

  .simple-table {
    padding: 0 50px;
    font-size: 24px;

    header {
      border-bottom: 1px solid #d2f95a;
      color: #d2f95a;
      background-color: black;
      position: relative;
      z-index: 10;
    }

    .table-content {
      position: relative;
      z-index: 5;
    }

    header, .row {
      display: flex; /* aligns all child elements (flex items) in a row */
    }

    .row {
      border-bottom: 1px solid #d2f95a;
      padding: 15px 0;
    }

    .col {
      flex: 1; /* distributes space on the line equally among items */
      white-space: nowrap;
    }
  }

  .slide-2, .slide-2-head {
    opacity: 0;
  }

  .slide-3, .slide-3-head {
    opacity: 0;
  }
}
