/*
 * T_adlermatchstatswise
 */

$className: T_adlermatchstatswise;

.T_adlermatchstatswise.animation {

  .slide-1, .slide-1-head {
    opacity: 0;
  }

  .slide-2, .slide-2-head {
    opacity: 0;
  }

  .slide-3, .slide-3-head {
    opacity: 0;
  }

}
