/*
 * T_playerstats
 */
.T_playerstats.animation {

  circle.circle-chart__circle {
    opacity: 0;
  }

}
