/*
 * T_wc_seasontotals
 */

$className: T_wc_seasontotals;

.T_wc_seasontotals.animation {

  .qatar-logo, .main-headline, .stats-type, .main-header, .powered-by, .stat-row {
    opacity: 0;
  }

}
