/*
 * T_playerstats
 */
.T_playerstats {

  .comp-player {
    img {
      border: 10px solid black;
    }
  }

  .content-block {
    position: absolute;
    width: 1250px;
    right: 40px;
    top: 32px;
    bottom: 32px;
  }

  .player {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 630px;
    height: 1048px;
    z-index: 10;
    display: flex;
    align-items: flex-end;

    img, video {
      z-index: 10;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom;
    }
  }

  .team-head-block {
    position: relative;
    top: 0;
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .team-name {
      text-transform: uppercase;
      font-size: 48px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      margin-right: 10px;
      height: 100%;
      line-height: 145px;
      padding: 0 25px;
      text-align: right;
    }

    .team-logo {
      height: 160px;
      background-color: black;
      padding: 15px 30px;
      width: 250px;

      img, video {
        display: block;
        height: 100%;
        width: 100%;
        margin: auto;
        object-fit: contain;
      }
    }
  }

  .player-head-block {
    height: 260px;
    width: 100%;
    position: relative;
    display: flex;
    margin-bottom: 10px;

    .player-number {
      width: 210px;
      height: 100%;
      margin-right: 10px;
      background-color: rgba(0, 0, 0, 0.3);
      font-size: 135px;
      font-weight: 600;
      line-height: 245px;
      flex: 0 0 auto;
      background-color: black;
      text-align: center;
    }

    .player-name-block {
      width: 1030px;
      font-size: 70px;
      text-transform: uppercase;
      flex: 0 0 auto;
      background-color: black;
      text-align: right;

      .player-name {
        color: black;
      }

      .player-name, .player-position {
        height: 50%;
        line-height: 120px;
        padding: 0 25px;
      }

    }

    .visual-position {
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      background-color: black;
    }
  }

  .factsListBg {
    width: 100%;
    height: 570px;
    position: relative;
    bottom: 0;
    background-color: black;
    overflow: hidden;
  }

  .factsList {
    position: relative;
    margin-left: 120px;
    z-index: 15;
    width: 1100px;
    height: 510px;

    font-size: 36px;
    font-weight: normal;
    color: rgb(255, 255, 255);
    line-height: 50px;
    text-align: left;
    margin-top: 50px;

    ul, ol {
      list-style: none;
    }

    .li-wrapper-content {
      width: 1050px;
    }

    //li{list-style-type:none;}
    li:before, p:before {
      //content: '\B7';
      //font-size: 120px;
      line-height: 1;
      vertical-align: top;
      position: absolute;
      left: 10px;
      top: 15px;
      border-top: solid transparent;
      border-bottom: solid transparent;
      border-left: solid #f00;
      border-width: 12px;
      border-left-color: inherit;
      content: '';
      border-left-width: 20px;
    }

    ol {
      li:before, p:before {
        content: counter(step-counter);
        border: none;
        top: -4px;
        font-size: 80px;
      }
    }

    .li-wrapper {
      color: white;
    }

    li, p {
      counter-increment: step-counter;
      position: relative;
      background-repeat: no-repeat;
      background-position: top left;
      margin: 0 0 50px 0px;
      padding-left: 75px;
      margin-left: -50px;
    }
  }

  .mini-lineup {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .field {
    position: relative;
    width: 100%;
    height: 100%;
    left: 15px;
    bottom: 20px;

    .formation > div {
      position: absolute;
      width: 15px;
      border-radius: 100%;
      height: 15px;
    }

    .formation > div:not(.current-player) {
      background-color: rgba(255, 255, 255, 0.4) !important;
    }
  }


  .stats-block {
    position: absolute;
    top: 439px;
    width: 100%;
    /* width: 1810px; */
    left: 0;
    right: 55px;
    right: 0;
    height: 575px;
    margin: auto;
    box-sizing: border-box;
    display: flex;

    .live-stats-label {
      position: relative;
      line-height: 1;
      font-size: 34px;
      text-transform: uppercase;
      margin-top: 20px;
      margin-bottom: 38px;
    }

    .season-stats {
      background-color: black;
      width: 490px;
      position: relative;
      display: none !important;

      .bundesliga-logo-block {
        position: absolute;
        right: 20px;
        top: 23px;


        & > div:first-child {
          display: flex;
          font-size: 28px;
          text-transform: uppercase;
          align-items: center;
          line-height: 1;
          letter-spacing: 3px;
        }

        .bundesliga-logo {
          margin-right: 4px;
        }
      }


      &.season-stats-away .bundesliga-logo-block {
        left: auto;
        right: 20px;
      }

      .bundesliga-season {
        font-size: 30px;
        line-height: 1;
      }

      .season-stats-label {
        position: absolute;
        line-height: 1;
        left: 20px;
        top: 20px;
        font-size: 34px;
      }

      &.season-stats-home .season-stats-label {
        left: auto;
        right: 20px;
      }

      .icon-label {
        font-size: 30px;
        text-align: center;
        text-transform: uppercase;
      }

      .season-stats-entries {
        font-size: 118px;
        line-height: 1;
        margin-top: 108px;
        margin-left: 60px;

        & > div {
          display: flex;
          align-items: center;
          margin-bottom: 26px;
          position: relative;
        }

        .played {
          .icon-label {
            width: 110px;
            text-align: center;
            margin-left: -10px;
          }
        }
      }


      .season-stats-entries img {
        display: block;
        width: 80px;
      }

      .season-stat-value {
        position: absolute;
        top: 0;
        left: 170px;
      }

    }

    .live-stats-container {
      //width:800px;
    }

    .live-stats {
      width: 100%;
      margin: auto;
      background-color: black;
      height: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      overflow: hidden;
    }

    .svg-star {
      width: 29px;
      display: inline-block;
      margin-right: 10px;
    }

    .StatRow {
      margin: auto;
      width: 50%;
      height: 220px;
      position: relative;
      margin-bottom: 8px;
      display: inline-block;
      text-align: center;
      vertical-align: top;
    }

    //.StatRow:first-child {
    //  margin-top: 25px;
    //}

    .stat-home-value, .stat-away-value {
      position: absolute;
      top: 0;
      font-size: 60px;
    }

    .stat-home-value {
      right: 475px;
    }

    .stat-name {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 32px;
      top: 10px;
      line-height: 1;
      width: 460px;
      margin: auto;
      height: 65px;
      display: flex;
      /* overflow: hidden; */
      align-items: center;
      justify-content: center;
    }

    .stat-home-percent {
      position: absolute;
      top: 60px;
      font-size: 40px;
    }

    .stat-live-title {
      font-size: 30px;
      line-height: 35px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }


    .stat-circle {
      position: relative;
      width: 130px;
      margin: auto;
      height: 130px;
      margin-bottom: 5px;

      .circle-chart {
        width: 100%;
        height: 100%;
      }

      .stat-live-value, .stat-live-unit {
        position: absolute;
        top: 23%;
        left: 0;
        text-align: center;
        width: 100%;
        font-size: 46px;
      }

      .stat-live-unit {
        top: 62%;
        font-size: 26px;
      }

      .circle-chart__circle {
        transform: rotate(-90deg); /* 2, 3 */
        transform-origin: center; /* 4 */
      }

    }

  }
}

