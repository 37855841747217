/*
 * T_wc_goal
 */
 $className: T_goal_alarm;

 .#{$className} {
   font-family: "Rubik";
   //font-weight: 400;
   -webkit-font-smoothing: antialiased;
    position: relative;
    z-index: 9;


   .alarm-goal-headline {
     text-transform: uppercase;
   }

   .green-glow {
     color: #d2f95a;
   }

   .qatar-logo {
     position: absolute;
     z-index: 10;
     width: 430px;
     right: 80px;
     -webkit-filter: drop-shadow(2px 2px 2px black);
     filter: drop-shadow(2px 2px 2px black);
     bottom: 110px;

     img {
       width: 100%;
     }
   }

   .wc-content {
     position: absolute;
     z-index: 10;
     width: 100%;
     height: 100%;
   }

   .content {
     height: 100%;
   }

   .main-header {
     font-size: 100px;
     font-weight: bold;
     display: flex;
     justify-content: flex-start;
     line-height: 1;
     z-index: 100;
     position: relative;
     text-transform: uppercase;
   }

   .headline {
     font-family: "EdoSZ";
     font-size: 215px;
     position: absolute;
     top: 463px;
     text-align: center;
     left: 0;
     width: 100%;
   }

   .date-container {
     display: flex;
   }

   .container {
     display: flex;
     height: 100%;

     .left {
       flex: 1;
       margin-top: 70px;
       align-items: center;
       display: flex;
       flex-direction: column;

       .comp-player-triko {
         position: relative;
         top: 50px;
       }

       .team-name {
         font-size: 30px;
         line-height: 1;
         text-align: center;
         text-transform: uppercase;
         font-weight: 400;
         margin-top: 25px;
         white-space: nowrap;
       }
     }

     .right-block-side {

      .bottom-block {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 20px;
      }

      .match-status {
        padding: 8px 12px;
        height: 70px;
        background-color: white;
        .status-caption {
          opacity: 0;
          font-size: 50px;
          display: list-item;
          list-style-type: disc;
          color: red;
          list-style-position: inside;
        }
        .status-background {
          background-color: white;
        }
      }

      .comp-match-score-widget {
        position: relative;
      }

       .subheadline-and-logo {
         display: flex;
         justify-content: space-between;
         margin-bottom: 125px - 50px;

         .subheadline {
           font-size: 39px;
           color: #fff;
           z-index: 1;
           text-transform: uppercase;
           font-weight: 400;
           left: 50px;
           position: relative;
         }
       }

       .pitch-container {
         padding-top: 55px;
         overflow: hidden;
         /* margin-top: -12px; */
         position: absolute;
         bottom: 65px;
         justify-content: center;
         display: flex;
         width: 100%;

         .pitch {
           width: 929px;
           background-repeat: no-repeat;
           height: 669px;
           position: relative;

           .circle {
             width: 60px;
             height: 60px;
             line-height: 60px;
             border-radius: 50%;
             background-color: #dd0e26;
             position: absolute;
             text-align: center;
             opacity: 0;
             font-size: 38px;
             font-weight: 400;

             &.goal {
               width: 100px;
               height: 100px;
               line-height: 100px;
               background-color: rgb(222, 244, 119);
             }

             &.shooter {
               width: 80px;
               height: 80px;
               line-height: 80px;
               font-size: 55px;
             }
           }

           svg {
             width: 100%;
             height: 100%;
           }

           path {
             fill: none;
             stroke-width: 8;
             stroke-linecap: round;
             stroke: rgb(235, 235, 239);
             opacity: 0;

             &.event-16 {
               stroke-width: 12;
               stroke: rgb(222, 244, 119);
             }
           }
         }
       }
     }
   }

   .left-block-side {
     position: relative;
     left: 0;
     top: 0;
     z-index: 1;
     width: 480px;
     height: 100%;
   }

   .right-block-side {
     position: relative;
     right: 0;
     width: 1435px;
     height: 100%;
     z-index: 1;
   }
 }
