.comp-pitch-tactics {
  display: flex;
  align-items: center;
  justify-content: center;

  .formation-block {
    width: 100%;
    height: 100%;
    //height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding-top: 0;
  }


  .line {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;

    .line:last-child {
      margin-bottom: 0;
    }
  }

  .lineup-player {
    position: relative;

    &.fp-1 {
      margin-top: 30px;
    }

    .shirt-number {
      font-size: 32px;
      color: #004d5c;
      position: absolute;
      top: 15px;
      width: 100%;
      text-align: center;
      font-weight: 500;
    }

    .player-name {
      position: absolute;
      left: -65px;
      right: 0;
      width: 245px;
      text-align: center;
      font-weight: 600;
      font-size: 25px;
      top: 102px;
      line-height: 1;
    }
  }


  .comp-player-triko {
    top: 0;
    position: relative;

    .team-logo-container, .player-info {
      display: none;
    }

    .player {
      .trikot {
        width: 110px;

        .player-number {
          position: absolute;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 36px;
          top: 25px;
          font-weight: 400;
          line-height: 1;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: black;
        }
      }
    }

  }

  .card-yellow, .card-red {
    width: 22px;
    height: 30px;
    position: absolute;
    border-radius: 4px;
    right: -8px;
    top: 66px;
  }

  .card-yellow {
    background-color: #f4ed0f;
  }

  .card-red {
    background-color: #f20c33;
  }



  .substituted {
    position: absolute;
    right: -20px;
    top: 16px;

    img.arrow-pitch {
      width: 25px;
      display: block;
    }
  }


  .goal-balls {
    width: 35px;
    position: absolute;
    bottom: 20px;
    right: 98px;

    .goal-icon {
      width: 32px;

      &.own-goal {
        background-color: #ff0a0a;
        border-radius: 20px;
      }
    }
  }


}
