.comp-backgrounds {
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  //z-index: 5;

  & > div {
    width: inherit;
    height: inherit;
  }
}
