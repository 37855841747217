.teams-block {
  position: absolute;
  top: 1145px;
  width: 100%;

  .home {
    left: 120px;
    top: -320px;
  }

  .away {
    right: 120px;
    top: -320px;
    left: auto;
  }

}

.team-vs {
  top: 835px;
}

.headline {
  top: 600px;
}

.custom-background-container {
  width: 1920px;
  height: auto;
}

.gameInfo {
  top: 1202px;
}

.sponsors {
  &.sponsor-del {
    left: 70px;
    right: auto;
    margin: auto;
  }

  &.sponsor-sap {
    right: 50px;
    width: auto;
    top: auto;
    bottom: 70px;
  }
}
