.comp-alarm-live-status-badge {
  margin-right: 100px;
  &.live-status {
    text-transform: uppercase;
    font-size: 42px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    .white-bg, .red-bg {
      padding: 4px 8px;
      background-color: #fc0031;
      color: #ffffff;
      display: flex;
      align-items: center;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    .white-bg {
      position: absolute;
      z-index: 4;
      background-color: #ffffff;
      color: #fc0031;

      .circle {
        background-color: #fc0031;
      }
    }

    .red-bg {
      z-index: 3;
      background-color: #fc0031;
      color: #ffffff;

      .circle {
        background-color: #ffffff;
      }
    }
  }

  .circle {
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    align-items: center;
    margin: 0 4px;
    top: -1px;
    position: relative;
  }

  .animation-container {
    position: relative;
    width: 160px;
    height: 54px;
    overflow: hidden;
  }

  .white-bg-positioner, .red-bg-positioner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }

  .white-bg {
    animation: revealHide 4s cubic-bezier(0.2, 0.6, 0.2, 1) forwards infinite;
  }

  .blink-me {
    animation: blink-animation 1s steps(5, start) infinite;
  }

  .circle {
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    margin-right: 8px;
  }

  @keyframes revealHide {
    0%, 100% {
      clip-path: inset(0 100% 0 0);
    }
    40%, 50% {
      clip-path: inset(0 0 0 0);
    }
    100% {
      clip-path: inset(0 0 0 100%);
    }
  }

  @keyframes blink-animation {
    50% {
      opacity: 0;
    }
  }
}
