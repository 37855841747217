/*
 * T_adlerreferee
 */

$className: T_adlerreferee;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;
  color: #fff;

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      right: 49px;
      width: 162px;
      top: 45px;
    }

    &.sponsor-sap {
      left: 50px;
      width: 180px;
      top: 45px;
    }
  }

  .referees {
    position: absolute;
    top: 373px;
    line-height: 1.2;
    width: 100%;
    font-size: 58px;

    //.headReferee1 {
    //  position: absolute;
    //  left: 240px;
    //}
    //
    //.headReferee2 {
    //  position: absolute;
    //  left: 240px;
    //  top: 205px;
    //}
    //
    //.lineReferee1 {
    //  position: absolute;
    //  left: 1080px;
    //  top: 205px;
    //}
    //
    //.lineReferee2 {
    //  position: absolute;
    //  left: 1080px;
    //}
  }

  .teams-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    color: black;

    .team-logo-container {


      img {
        //transform: rotateZ(22deg);
      }
    }

    .team-vs {
      transform: rotateZ(22deg);
      color: black;
    }

    .team-vs {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0px;
      top: 45px;
      font-size: 40px;
    }

    .home, .away {
      position: absolute;
      width: 260px;
      height: 260px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .home {
      left: 1610px;
      top: 800px;
    }

    .away {
      left: 1610px;
      top: 800px;
    }
  }

  .referees {
    & > div {
      width: 580px;
      text-align: left;
      word-spacing: 100vw;
      position: relative;
    }
  }

  .main-referees {
    top: 368px;
    left: 192px;
    display: flex;
    /* height: 100%; */
    flex-direction: row;
    justify-content: flex-start;
  }

  .line-referees {
    top: 800px;
    left: 192px;
    display: flex;
    /* height: 100%; */
    flex-direction: row;
    justify-content: flex-start;
  }


  .headline, .headline2 {
    font-family: "Predator-Sans-Cut";
    font-size: 140px;
    position: absolute;

    text-align: left;
    left: -30px;
    width: 100%;
    font-style: italic;

    div {
      position: absolute;
      left: 0;
      top: 0;
      text-align: left;
      width: 100%;
      z-index: 2;

      &.main-line {
        letter-spacing: 0.010em;
        position: relative;

        div {
          position: relative;
        }
      }

      &.ghost-line {
        color: #cc0a1a;
        z-index: 1;
        left: 10px;
        letter-spacing: 0;
      }
    }
  }

  &.side-away {
    .headline, .headline2 {
      div {
        &.ghost-line {
          color: #054888;
        }
      }

    }
  }

  .headline {
    left: 170px;
    width: 100%;
    top: 120px;
  }

  .headline2 {
    left: 170px;
    width: 100%;
    top: 560px;
  }

}

