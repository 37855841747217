/*
 * T_wc_mvp
 */

$className: T_wc_mvp;

.T_wc_mvp.animation {

  .qatar-logo, .main-headline, .comp-pitch-tactics, .player-home {
    opacity: 0;
  }

}
