/*
 * T_wc_standings
 */
$className: T_wc_standings;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;

  @mixin row-gradient {
    border-bottom: 4px solid #3b7e89;
    background: linear-gradient(to right, #052635, transparent);
  }

  .group-table {
    align-self: flex-start;
    position: relative;
    font-size: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .group-label {
      font-size: 60px;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 1;
      margin-top: 20px;
    }

    .group-content {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 400;
    }

    .table-head {
      display: flex;
      justify-content: flex-end;
      padding: 2px 20px;
      font-size: 20px;
      font-weight: 400;
      //margin-right: 34px;
      text-align: center;
      min-height: 45px;
    }

    .table-head {
      border-bottom: 4px solid #3b7e89;
    }

    .table-row {
      display: flex;
      align-items: center;
      border-bottom: 4px solid #3b7e89;
      padding: 0 16px;
      min-height: 65px;


      &.mark-row {
        //border-bottom: 4px solid #ffffff;
        //border-top: 4px solid #ffffff;
      }

      &.mark-row + .mark-row {
        //border-top: 0px solid #ffffff;
      }

      &:nth-last-child {
        border-bottom: inherit;
      }

      &.first-group.mark-row-gradient {
        @include row-gradient;
      }

      &.second-group.mark-row-gradient {
        @include row-gradient;
      }

      //&.first-group:nth-child(3) {
      //  @include row-gradient;
      //}
      //
      //&.first-group:nth-child(4) {
      //  @include row-gradient;
      //}
      //
      //&.first-group:nth-child(5) {
      //  @include row-gradient;
      //}

      &.first-group.mark-row {
        border-image: linear-gradient(to right, #caf069, #3b7e89) 1;
      }

      &.second-group.red-mark-row {
        border-image: linear-gradient(to right, #da0a0b, #3b7e89) 1;
      }
    }

    .table-head, .table-row {
      display: flex;
    }

    .col {
      flex: 1;
      text-align: left;
    }

    .col-pos {
      flex: 0;
      min-width: 28px;
    }

    .col-logo {
      margin-right: 15px;
      display: flex;
      align-items: center;


      .team-logo {
        height: 40px;
        width: 60px;
      }

      .team-logo.svg-logo {
        padding: 0;
      }
    }

    .col-team {
      margin-right: 20px;
      min-width: 317px;
      display: flex;
      line-height: 1;
      align-items: center;

      img.country-flag {
        padding: 0;
      }
    }

    .col-points {
      //margin-left: 5px;
      width: 60px;
    }

    .col-diff {
      //margin-left: 5px;
      min-width: 85px;
    }

    .green-glow {
      color: #cff56a;
      font-weight: bold;
    }

    .col-pkt {
      //margin-left: 5px;
      width: 70px;
    }

  }

  .logo-positioner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 380px;
    margin-right: 60px;
  }

  .qatar-logo {
    position: absolute;
    top: 55px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .main-header {
    top: 100px;
    left: 100px;
    font-size: 160px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 1px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .coach-info {
    position: absolute;
    top: 650px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 1px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .team-logo-container {
    position: absolute;
    z-index: 5;
    left: 60px;
    top: 350px;
    width: 340px;

  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .venue-container {
    text-transform: uppercase;
  }

  .vs-team-info {
    font-size: 26px;
  }

  .wc-content {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;

    .wc-bg-content {
      opacity: 0.8
    }
  }

  .lineup {
    position: absolute;
    left: 580px;
    top: 130px;
    z-index: 5;
    text-transform: uppercase;

    .lineup-content {
      font-size: 30px;
      line-height: 1.4;
    }

    .player {
      display: flex;

      .shirt-number {
        width: 50px;
        text-align: right;
        margin-right: 15px;
        font-weight: 300;
      }

      .player-name {
        font-weight: 600;
      }
    }

    .wc-headline {
      font-size: 70px;
      font-weight: 700;
    }
  }

  .lineup-reserve {
    left: 1080px;
    font-size: 40px;

    .lineup-content {
      font-size: 26px;
      //line-height: 1.4;
    }

    .player {
      .player-name {
        font-weight: 400;
      }
    }


    .wc-headline {
      margin-top: 25px;
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }


  .competition-logo-container {
    border-radius: 20px;
    height: 100px;
    width: 150px;
    box-sizing: border-box;
    align-self: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .wc-standing-container {
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  .green-glow {
    color: #d2f95a;
  }

  .main-title {
    font-size: 70px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    display: flex;
    align-items: center;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 37px;
    text-align: center;
    justify-content: center;
  }

  .wc-standing-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .competition-positioner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 380px;
    width: 100%;
    margin-left: 40px;
  }

  .second-title {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: normal;
    text-align: left;
    padding-left: 26px;
    display: flex;
    align-items: center;
    width: inherit;
    line-height: 1;
  }

  .wc-standing-content-container {
    display: flex;
    position: absolute;

    .group-table {
      display: flex;
      margin: 0 40px;
      justify-content: space-between;
      position: relative;
    }


    &.single-side {
      justify-content: center;
    }
  }
}
