/*
 * T_adlerlivematchdays
 */

$className: T_adlerlivematchdays;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;

  color: #0F1F3A;

  .team-logo-container {
    position: absolute;
    width: 130px;
    height: 130px;
    top: 104px;
    left: 19px;
    transform: rotateZ(
        -22deg);

    img {
      transform: rotateZ(
          22deg);
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: relative;
    }
  }

  .headline {
    font-family: "Predator-Sans-Cut";
    font-size: 126px;
    top: 15px;
    position: absolute;
    transform-origin: center center;

    text-align: center;
    left: -30px;
    width: 100%;
    font-style: italic;
    color: white;

    div {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 100%;
      z-index: 2;

      &.main-line {
        letter-spacing: 0.015em;
        left: 2px;
        position: relative;
      }

      &.ghost-line {
        color: #cc0a1a;
        z-index: 1;
        letter-spacing: 0
      }
    }
  }

  &.side-away {
    .headline {
      div {
        &.ghost-line {
          color: #054888;
        }
      }

    }
  }

  .sponsors {
    position: absolute;

    &.sponsor-sap {
      right: 65px;
      width: 260px;
      top: 90px;
    }
  }

  .live-matchdays-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 240px;
    bottom: 0;
    width: 1760px;
    margin: auto;
    overflow: hidden;
    color: #fff;
    overflow: visible;

    .match {
      line-height: 1;
      display: flex;
      justify-content: center;
      height: 110px;
      align-items: center;
      font-size: 42px;
      position: relative;
      overflow: visible;

      .double-border, .double-border-first {
        position: absolute;
        top: -3px;
        left: 0;
        width: 100%;
        height: 100%;
        /* border-top: 2px solid transparent; */
        border-bottom: 2px solid transparent;
        border-image-source: radial-gradient(circle, #fff 40%, transparent 100%);
        border-radius: 25px;
        border-image-slice: 1;
      }

      .double-border-first {
        border-bottom: none;
      }


      border-bottom: 2px solid transparent;
      border-image-slice: 1;
      border-image-source: radial-gradient(circle, #fff 40%, transparent 100%);
      border-radius: 25px;

      &:first-child {
        border-top: 2px solid transparent;
        border-image-slice: 1;
        border-image-source: radial-gradient(circle, #fff 40%, transparent 100%);

        .double-border-first {
          border-bottom: none;
          border-top: 2px solid transparent;
          top: -7px;
        }
      }

      &.adler-match {
        color: #ff0000;
      }

      &.status-live {
        .match-status {
          color: #ff0000;
        }
      }

      .score-container {
        display: flex;
        justify-content: center;
        position: relative;
        width: 200px;
        font-size: 50px;
        line-height: 1;
      }
    }


    .home-logo, .away-logo {
      height: 100%;


      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        min-width: 108.17px;
      }
    }

    .home-logo {
      margin-left: 45px;
    }

    .away-logo {
      margin-right: 45px;
    }

    .home-name {
      width: 540px;
      text-align: right;
      white-space: nowrap;
    }

    .away-name {
      width: 540px;
      text-align: left;
      white-space: nowrap;
    }

    .match-status {
      position: absolute;
      bottom: -20px;
      width: 100%;
      text-align: center;
      font-size: 19px;
      font-family: "Predator-Sans-SemiLight";
    }
  }

  .extra-time-info {
    font-size: 26px;
    color: white;
    position: absolute;
    left: 146px;
    top: 20px;
    color: #ff0000;
  }


}
