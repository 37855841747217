/*
 * T_wc_countrystadiums
 */

$className: T_wc_countrystadiums_video;

.T_wc_countrystadiums_video.animation {

  .qatar-logo, .main-headline, .comp-pitch-tactics, .lineup-player, .substitute-line, .substitutions {
    opacity: 0;
  }

}
