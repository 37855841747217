/*
 * T_wc_heatmap
 */

$className: T_wc_heatmap;

.T_wc_heatmap.animation {

  .qatar-logo, .main-headline, .pitch-container, .player, .team-home, .team-away {
    opacity: 0;
  }

}
