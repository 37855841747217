/*
 * T_tm_deal
 */

$className: T_tm_top_10;

.#{$className} {
  font-family: "Source-Regular";
  -webkit-font-smoothing: antialiased;


  .comp-backgrounds-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .comp-backgrounds-container-content {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  
  .content {
    position: relative;
    height: 100%;
  }

  .block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .fact-content-wrapper {
    display: flex;
    align-items: center;
    max-width: 50%;
  }

  .custom-background-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;

    .custom-background {
      width: 100%;
      height: 60%;
      object-fit: contain;
      position: absolute;
      top: 280px;
    }
  }

  .box {
    width: 470px;
    height: 885px;
    margin: 95px auto;
    background-color: #0d4d5c;
    padding: 45px;
  }

  .box-title {
    text-align: center;
    font-size: 48px;
    padding-top: 85px;
  }

  .box-row {
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    justify-content: space-between;
    .box-row-item-empty {
      width: 20px;
    }
    .box-row-item {
      display: flex;
      text-align: center;
      height: 90px;
      width: 160px;
      flex-direction: column;
      align-items: center;
      .box-row-item-title {
        color: #3CA8FF;
        font-size: 18px;
        font-weight: 500;
        height: 30px;
        font-family: "Source-Semibold";
      }
      .box-row-item-value {
        font-size: 36px;
        font-weight: 700;
        padding-bottom: 24px;
        font-family: "Source-Black";
      }
      .box-row-item-value-small {
        font-size: 20px;
        font-weight: 500;
        width: 100px;
      }
    }
    .box-row-item-right {
      height: 90px;
      width: 2px;
      position: relative;
      top: -20px;
    }
  }

  .tm-logo {
    display: flex;
    margin: auto;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    width: 100%;
  }

  .qatar-logo {
    width: 320px;
    //filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }

    position: relative;
    padding-bottom: 45px;
  }

  .green-glow {
    color: #d2f95a;
  }

  .main-header-container {
    display: flex;
    align-items: center;
    padding-top: 165px;
    padding-left: 100px;
    padding-right: 100px;
    justify-content: center;
  }

  .main-header-logo {
    height: 150px;
    object-fit: contain;
    padding-right: 56px;
  }

  .main-header-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .main-header {
    font-size: 72px;
    font-family: "Source-Black";
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
  }

  .main-header-desc {
    font-size: 42px;
    font-family: "Source-Semibold";
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
    color: #5CA6FF;
  }

  .green-line {
    width: 30px;
    height: 4px;
    background-color: #d2f95a;

  }

  .sportworld-image-shadow {
    position: absolute;
    z-index: 100;
    height: 55px;
  }

  .information-box {
    display: flex;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    flex: 1;
  }

  .box-player {
    width: 30%;
    height: 80%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .box-container {
    width: 70%;
    min-height: 100px;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 500;
    .box-player-header {
      display: flex;
      flex-direction: row;
      color: #5CA6FF;
    }
    .box-player-header-prefix {
      // width: 156px;
      width: 253px;
    }
    .box-player-header-name {
      // width: 250px;
      width: 364px;
      display: flex;
      align-items: flex-end;
    }
    .box-player-header-age {
      width: 180px;
      text-align: center;
      padding-right: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    .box-player-header-market {
      width: 300px;
      text-align: center;
    }
    .box-background-blue {
      height: 100%;
      text-transform: uppercase;
      background-size: cover;
    }
    
  }
}

.f-1200x1500 {
  .#{$className} {
    @import "./style-1200x1500";
  }
}

.f-1080x1920 {
  .#{$className} {
    @import "./style-1080x1920";
  }
}



.top-players-list {
  align-content: flex-start;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  padding-top: 15px;
  overflow: auto;

  .top-players-list-container{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .sortable-container{

      .player-actions {
        display: flex;
        justify-content: space-between;
        margin-right: 15px;
        font-size: 32px;
        scale: 0.5;
      }
    }

    .item-selected {
      box-shadow: 0px 0px 0px 2px green ;
    }
  }
}
.player-item-scale {
  scale: 0.5;
  margin-bottom: 0 !important;
}
.player-item {
  position: relative;
  margin-right: 15px;
  margin-bottom: 25px;
  transition: all 0.20s;
  border: 1px solid transparent;
  cursor: pointer;

  .comp-backgrounds {
    width: 100%;
    height: 100%;
  }
  
  .player-item-av {
    background-color: rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    position: relative;
    color: white;
    font-size: 42px;
    height: 100px;
    .player-item-left {
      display: flex;
      position: relative;
      height: 100%;
      align-items: center;
      padding-left: 32px;
      padding-right: 20px;
    }
    .player-item-rank {
      width: 50px;
      color: #4F90E9;
      position: relative;
      z-index: 1;
    }
    .player-item-team {
      height: 50px;
      width: 50px;
      object-fit: contain;
      margin-right: 24px;
      z-index: 1;
    }
    .player-item-name {
      text-transform: uppercase;
      z-index: 1;
      width: 250px;
    }
    .player-item-age {
      width: 100px;
      text-align: center;
      z-index: 1;
    }
    .player-item-market {
      width: 300px;
      background-color: #4F90E9;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
}

  .media-item-data {
    line-height: 1.15;
    padding: 2px 2px;
    overflow-y: auto;
    height: 50px;

    .value {
      color: #1890ff;
    }
  }

  &:hover {
    //transform: scale(1.05);
    border: 1px solid #1890ff;
  }

  &:last-child {
    justify-content: flex-start;
  }

  .media-actions {
    width: 100%;
    text-align: right;

    .anticon-delete {
      position: absolute;
      left: 0;
      top: 4px;
    }

    .anticon:not(.anticon-delete) {
      margin-left: 3px;
    }
  }
}


.preview-item-av {
  width: 622px;
  height: 350px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}